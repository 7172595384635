import React from "react";
import titleBoardBackground from "../assets/images/title-board.png";
import styles from "./GreetingsBoard.module.scss";

const GreetingsBoard = ({ title = "" }) => {
  return (
    <div className={styles.wrapper}>
      <img
        src={titleBoardBackground}
        className="img-fluid"
        alt="greetings board home page"
      />
      <div className={styles.contentBox}>
        <h3 className="mb-0">{title}</h3>
      </div>
    </div>
  );
};

export default GreetingsBoard;
