import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { nestAPI } from "../common/api-endpoints";
import { NEST_WRAPPERS } from "../common/constants";
import HTTPService from "../common/httpService";

export const fetchAllNests = createAsyncThunk(
  "fetchAllNests",
  async (userId, thunkAPI) => {
    return await HTTPService.get(nestAPI, { populate: "owner", owner: userId })
      .then((res) => {
        return res.data.results;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue("Cannot fetch nests");
      });
  }
);

export const NEST_NAMES = {
  DEFAULT: { s: "s" },
  DOUBLE_STORY: { d1: "d1", d2: "d2" },
  PIRATE_SHIP: { t1: "t1", t2: "t2", t3: "t3" },
  CASTLE: { q1: "q1", q2: "q2", q3: "q3", q4: "q4" },
};

export const nestSlice = createSlice({
  name: "nest",
  initialState: {
    nests: [],
    loading: false,
    staking: true,
    activeNest: "",
    activeNestWrapper: NEST_WRAPPERS.DEFAULT,
  },
  reducers: {
    setStaking: (state, action) => {
      state.staking = action.payload;
    },
    setActiveNestWrapper: (state, action) => {
      if (action.payload <= 4 && action.payload >= 0) {
        state.activeNestWrapper = Object.values(NEST_WRAPPERS)[action.payload];
      }
    },
    setActiveNest: (state, action) => {
      state.activeNest = action.payload;
    },
  },
  extraReducers: {
    [fetchAllNests.fulfilled]: (state, action) => {
      state.loading = false;
      state.nests = action.payload;
    },
    [fetchAllNests.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllNests.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { setStaking, setActiveNestWrapper, setActiveNest } =
  nestSlice.actions;

export default nestSlice.reducer;
