import React, { useState } from "react";
import classNames from "classnames";
import Players from "./Players";
import Transactions from "./Transactions";
import Community from "./Community";
import HistorySearchInput from "./HistorySearchInput";
import active from "../../assets/images/active.png";
import styles from "./HistoryTabs.module.scss";

const tabMetaData = ["My Transaction", "Favourite", "All Players"];

const HistoryTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (tabIndex) => {
    setActiveTab(Number(tabIndex));
  };

  const activeTabBorder = (
    <div className={styles.borderBlock}>
      <img src={active} className="active img-fluid" alt="active" />
    </div>
  );

  return (
    <div className={styles.tabWrapper}>
      <div className={styles.mainLinkWrapper}>
        <div
          className={styles.tablinkWrapper}
          onClick={(e) => handleTabChange(e.target.getAttribute("data-tab-id"))}
        >
          {tabMetaData.map((tab, index) => {
            return (
              <div
                className={classNames(styles.tabLink, {
                  active: activeTab === index,
                })}
                data-tab-id={index}
                key={tab + index}
              >
                {tab}
                {activeTab === index && activeTabBorder}
              </div>
            );
          })}
        </div>
        <HistorySearchInput />
      </div>
      <div className={styles.tabContent}>
        {activeTab === 0 && <Transactions bgColor="green" />}
        {activeTab === 1 && <Community />}
        {activeTab === 2 && <Players bgColor="saffron" />}
      </div>
    </div>
  );
};

export default HistoryTabs;
