import border from "../assets/images/border.png";
import trophy from "../assets/images/trophy.png";
import "../assets/scss/components/trophy.scss";

const Trophy = ({ imgUrl = "" }) => {
  return (
    <div className="cmn-bird-trophy-block">
      <div className="cmn-bird-reward">
        {imgUrl ? (
          <img src={imgUrl} className="img-fluid" alt="eagle" />
        ) : (
          <p>No result!</p>
        )}
        <div className="cmn-trophy-block">
          <img src={trophy} className="img-fluid" alt="trophy" />
        </div>
      </div>
      <div className="border-block">
        <img src={border} className="img-fluid img-1" alt="border" />
      </div>
    </div>
  );
};

export default Trophy;
