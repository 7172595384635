import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { removeCommunityAPI } from "../../common/api-endpoints";
import HTTPService from "../../common/httpService";
import { fetchCommunity } from "../../store/allPlayersSlice";
import SeoMetaTags from "../../components/SeoMetaTags";
import Trophy from "../../components/Trophy";
import User from "../../components/User";
import heart from "../../assets/images/fill-heart.png";
import "../../assets/scss/components/community.scss";

const Community = () => {
  const dispatch = useDispatch();

  const { loading, community } = useSelector((state) => state.allPlayers);

  useEffect(() => {
    dispatch(fetchCommunity());
  }, [dispatch]);

  const handleRemoveFavoruite = (stakeId) => {
    HTTPService.post(removeCommunityAPI, {
      stakeId: stakeId,
    })
      .then((res) => {
        dispatch(fetchCommunity());
        toast.success(res.message);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  let nonNullStakingCommunity =
    community.filter((community) => community.communityStake !== null) || [];

  return (
    <React.Fragment>
      <SeoMetaTags title={"Community"} />
      {nonNullStakingCommunity.length > 0 ? (
        nonNullStakingCommunity.map((player) => {
          return (
            <div className="community-block" key={player.id}>
              <div className="heart-block">
                <img
                  src={heart}
                  className="img-fluid"
                  alt="empty heart"
                  onClick={() =>
                    handleRemoveFavoruite(player.communityStake.id)
                  }
                />
              </div>
              <User
                walletId={player.communityStake.user?.xrpAddress}
                profilePic={player.communityStake.user?.profilePic}
              />
              <div className="token-block"></div>
              <div className="duration-wrapper-block">
                <h6 className="tx-sixth f-400">Duration</h6>
                <div className="duration-block">
                  <h2 className="mb-0">
                    {player.communityStake.duration}
                    <span>:</span>00<span>:</span>00
                  </h2>
                </div>
                <div className="duration-block-timer">
                  <h5>Days</h5>
                  <h5>Hours</h5>
                  <h5>Mins</h5>
                </div>
              </div>
              <div className="first-block-wrapper">
                <div className="first-text-blk">
                  <div className="cmn-bird-wrapper-block">
                    <img
                      src={player.communityStake.images[0]}
                      className="img-fluid kg-1"
                      alt="eagle"
                    />
                    <img
                      src={player.communityStake.images[1]}
                      className="img-fluid kg-2"
                      alt="eagle"
                    />
                  </div>
                </div>
              </div>
              <Trophy imgUrl={player.stakeReward[0].imgUrl} />
            </div>
          );
        })
      ) : loading ? (
        <p>Loading.....</p>
      ) : (
        <p>No community friend found!</p>
      )}
    </React.Fragment>
  );
};

export default Community;
