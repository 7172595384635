import { Children, isValidElement, cloneElement } from "react";
import { Header } from "../components";
import "../assets/scss/layout/layout.scss";
import usePlayMusic from "../hook/usePlayMusic";




const Layout = ({ children, sidebar }) => {
  const { playAppMusic } = usePlayMusic();



  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { handleMusic: playAppMusic });
    }
    return child;
  });

  return (
    <div className={sidebar ? "full-layout" : "with-header"}>
      <div className="container">
        <Header handleMusic={playAppMusic} />
        {sidebar ? (
          <div className="custom-layout">
            {sidebar}
            <div className="content-wrapper">{childrenWithProps}</div>
          </div>
        ) : (
          <div className="with-header-layout">{childrenWithProps}</div>
        )}
      </div>
    </div>
  );
};

export default Layout;
