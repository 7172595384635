import { combineReducers, configureStore } from "@reduxjs/toolkit";
import allPlayers from "./allPlayersSlice";
import auth from "./authSlice";
import nest from "./nestSlice";
import nft from "./nftSlice";
import stake from "./activeStakeSlice";
import allTransactions from "./transactionSlice";
import inventory from "./inventoryPotionsSlice";

export const store = configureStore({
  reducer: combineReducers({
    auth,
    nest,
    nft,
    stake,
    allPlayers,
    allTransactions,
    inventory,
  }),
});
