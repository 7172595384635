import icon from "../assets/images/trophy-icon.png";
import webIcon from "../assets/images/web-icon.png";
import "../assets/scss/pages/rewards.scss";
import CreditsBLock from "../components/CreditsBlock";
import SeoMetaTags from "../components/SeoMetaTags";

const creditsList = [
  {
    name: "Legitsoft Developers",
    description: "Blockchain Software Development Company",
    profilePic: "https://supereaglebucket.s3.amazonaws.com/profilepics/5.png",
    twitter: "",
    web: "https://legitsoft.in/en/home",
    webIcon: webIcon,
  },
  {
    name: "Akagami Dio",
    description: "Lead Artist, Pixel",
    profilePic: "https://supereaglebucket.s3.amazonaws.com/profilepics/1.png",
    twitter: "https://twitter.com/AkagamiPX",
    web: "",
    webIcon: "",
  },
  {
    name: "Sayali",
    description: "UI Artist",
    profilePic: "https://supereaglebucket.s3.amazonaws.com/profilepics/2.png",
    twitter: "",
    web: "https://www.behance.net/sayali-gujarathi",
    webIcon: webIcon,
  },
  {
    name: "Langtambun",
    description: "SuperEagle NFT Art",
    profilePic: "https://supereaglebucket.s3.amazonaws.com/profilepics/3.png",
    twitter: "",
    web: "https://www.fiverr.com/langtambun ",
    webIcon: webIcon,
  },
  {
    name: "WHATNEYESORE!",
    description: "Potion Design",
    profilePic: "https://supereaglebucket.s3.amazonaws.com/profilepics/4.png",
    twitter: "https://twitter.com/whatneyesore",
    web: "",
    webIcon: "",
  },
  {
    name: "Louie",
    description: "Social Media Manager/Tester",
    profilePic: "https://supereaglebucket.s3.amazonaws.com/profilepics/6.png",
    twitter: "https://twitter.com/XRPLouie",
    web: "",
    webIcon: "",
  },
  {
    name: "Brendan",
    description: "Lead Hype Man/Tester",
    profilePic: "https://supereaglebucket.s3.amazonaws.com/profilepics/6.png",
    twitter: "https://twitter.com/ViewsonBrews",
    web: "",
    webIcon: "",
  },
];

const Credits = () => {
  return (
    <div className="common-lap shop-main-wrapper">
      <SeoMetaTags title={"Credits"} />
      <div className="title-block">
        <div className="d-flex inner-tl">
          <div className=" mr-2">
            <img src={icon} alt="reward" className="img-fluid" />
          </div>
          <h3 className="tx-twelve">Credits</h3>
        </div>
      </div>
      <div className="common-inner-lap">
        {creditsList.map((credit) => {
          return <CreditsBLock key={credit.name} credit={credit} />;
        })}
      </div>
    </div>
  );
};

export default Credits;
