import { createSelector } from "@reduxjs/toolkit";

export const userTransactionSelectors = (state) => state.allTransactions;
export const allPlayersSelectors = (state) => state.allPlayers;

export const stakeRewardTransactionsSortedInDescOrderByDateSelector =
  createSelector([userTransactionSelectors], ({ transactions = [] }) =>
    transactions.filter((tx) => tx.txType === "stake_reward").reverse()
  );

export const otherTransactionsSortedInDescOrderByDateSelector = createSelector(
  [userTransactionSelectors],
  ({ transactions = [] }) =>
    transactions.filter((tx) => tx.txType !== "stake_reward").reverse()
);
