import React from "react";
import classNames from "classnames";
import { getMonthDayYear } from "../../utils/formateDate";
import eagle from "../../assets/images/eagle-1.png";
import styles from "./TransactionItem.module.scss";

const superEagleCoinGif =
  "https://xls20.supereaglecoin.co/static/media/coin.bf44681821295982296e.gif";

const TransactionItem = ({ transaction, bgColor }) => {
  return (
    <div className={classNames(styles.transactionBlock, bgColor)}>
      <div className={styles.trImgWrapper}>
        <div className={styles.trImgBlock}>
          {transaction.txType === "stake_reward" ? (
            <img
              src={superEagleCoinGif || eagle}
              className="img-fluid kg-1"
              alt="eagle"
            />
          ) : (
            <img
              src={superEagleCoinGif || eagle}
              className="img-fluid kg-1"
              alt="eagle"
            />
          )}
        </div>
      </div>
      <ul className={styles.contentBlock}>
        <li style={{ display: "flex", alignItems: "center" }}>
          <h6 className="f-900">
            {transaction.txType === "stake_reward"
              ? "Staking Reward"
              : "Random Genesis Eagle"}
          </h6>
        </li>
        <li>
          <h6>Amount</h6>
          <h6>
            {transaction.amount.value}{" "}
            {transaction.txType === "stake_reward" ? "SEC" : "XRP"}
          </h6>
        </li>
        <li>
          <h6>Type</h6>
          <h6>{transaction.txType}</h6>
        </li>
        <li>
          <h6>Transaction Id</h6>
          <h6 className="truncate-wallet" title={transaction.txHash}>
            {transaction.txHash}
          </h6>
        </li>
        <li>
          <h6>Date</h6>
          <h6>{getMonthDayYear(transaction.createdAt)}</h6>
        </li>
      </ul>
    </div>
  );
};

export default TransactionItem;
