import moment from "moment";

// use this function only to get active stake timings
export const getStakeTime = (stake) => {
  const startTime = stake.timeline
    .filter((t) => t.process === "start")
    .reverse()[0]?.time;
  const duration = stake.duration;
  const endTime = moment(startTime).add(duration, "d").toDate();
  const remainHours = moment(endTime).diff(moment(), "h");
  const remainSeconds = moment(endTime).diff(moment(), "s");

  return {
    duration,
    startTime,
    endTime: endTime.toString(),
    remainSeconds,
    remainHours,
  };
};
