import React, { useCallback, useEffect, useState } from 'react'
import SeoMetaTags from '../components/SeoMetaTags';
import notificationIcon from "../assets/images/notification.png";
import HTTPService from '../common/httpService';
import { clearNotificationAPI, getNotificationsAPI } from '../common/api-endpoints';
import styles from "./Notifications.module.scss";
import classNames from 'classnames';

const Notifications = () => {
  const [notifications, setNotifications] = useState<any[]>([]);

  const getNotifications = useCallback(() => {
    HTTPService.get(getNotificationsAPI)
      .then((res) => {
        if (res.status === 1) {
          const notify = [];
          for (let i = 0; i < res.data.results.length; i++) {
            notify.push(res.data.results[i]);
          }
          setNotifications(notify);
        }
      })
      .catch((e) => {
        // console.log("Error", e);
      });
  }, []);

  const handlleClearNotification = () => {
    HTTPService.get(clearNotificationAPI)
      .then((res) => {
        setNotifications([]);
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  useEffect(() => {
    getNotifications();
  }, [])

  return (
    <div className="common-lap shop-main-wrapper">
      <SeoMetaTags title={"Notifications"} />
      <div className="title-block">
        <div className="d-flex inner-tl">
          <div className=" mr-2">
            <img src={notificationIcon} alt="reward" className="img-fluid" />
          </div>
          <h3 className="tx-twelve">Notifications</h3>
        </div>
      </div>
      <div className={styles.notifications}>
        {notifications.length && <li className={classNames(styles.notificationItem, "cursor-pointer")} onClick={handlleClearNotification}>
          Clear Notifications
        </li>}
        {
          notifications.map((notification) => {
            return <li key={notification} className={styles.notificationItem}>
              <span>{notification.title}</span> {notification.message}
            </li>
          })
        }
      </div>
    </div>
  )
}

export default Notifications