import React from "react";
import notificationIcon from "../assets/images/notification.png";

const ActiveLink = ({ count }) => {
  return (
    <div className="notification-button">
      <img src={notificationIcon} className="img-fluid nt-icon" alt="nt-icon" />
      <div className="round-wrap">{count}</div>
    </div>
  );
};

export default ActiveLink;
