import classNames from "classnames";
import { useSelector } from "react-redux";
import ship from "../assets/images/ship-nest.png";
import { NEST_NAMES } from "../store/nestSlice";
import Nest from "./Nest";
import { activeStakeBirdsSelector } from "./selector";
import styles from "./TripleNestPirateShipWrapper.module.scss";

const TripleNestPirateShipWrapper = () => {
  const { inventory } = useSelector((state) => state.nft);
  const {
    t1: superEagles1,
    t2: superEagles2,
    t3: superEagles3,
  } = useSelector((state) => activeStakeBirdsSelector(state));

  const isWrapperEnabled = inventory.find(
    (nest) => nest.name === "Triple Nest Pirate Ship"
  );

  return (
    <div
      className={classNames("main-wrapper-1", styles.thirdWrapper, {
        active: isWrapperEnabled,
      })}
    >
      {!isWrapperEnabled && <h3>No Access</h3>}

      <img src={ship} className="img-fluid main-bg main-bg" alt="nest" />
      <div className={styles.birdNest}>
        <div className={styles.firstNest}>
          <Nest
            firstBird={superEagles1.nfts?.[0]}
            secondBird={superEagles1.nfts?.[1]}
            duration={superEagles1.duration}
            remainHours={superEagles1.remainHours}
            nestName={NEST_NAMES.PIRATE_SHIP.t1}
          />
        </div>
        <div className={styles.secondNest}>
          <Nest
            firstBird={superEagles2.nfts?.[0]}
            secondBird={superEagles2.nfts?.[1]}
            duration={superEagles2.duration}
            remainHours={superEagles2.remainHours}
            nestName={NEST_NAMES.PIRATE_SHIP.t2}
          />
        </div>
        <div className={styles.thirdNest}>
          <Nest
            firstBird={superEagles3.nfts?.[0]}
            secondBird={superEagles3.nfts?.[1]}
            duration={superEagles3.duration}
            remainHours={superEagles3.remainHours}
            nestName={NEST_NAMES.PIRATE_SHIP.t3}
          />
        </div>
      </div>
    </div>
  );
};

export default TripleNestPirateShipWrapper;
