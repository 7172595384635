import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import {
  accountInventoryAPI,
  accountUseInventoryItemAPI,
} from "../common/api-endpoints";
import HTTPService from "../common/httpService";
import { fetchActiveStakes } from "./activeStakeSlice";

export const fetchInventory = createAsyncThunk(
  "fetchInventory",
  async (_, thunkAPI) => {
    return await HTTPService.get(accountInventoryAPI)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e);
      });
  }
);

export const useInventoryPotionOnSelectedNest = createAsyncThunk(
  "useInventoryPotion",
  async ({ inventoryId, stakeId }, thunkAPI) => {
    const state = thunkAPI.getState();
    const dispatch = thunkAPI.dispatch;

    const refreshInventory = () => {
      dispatch(setUsePotionModalVisibility(false));
      dispatch(setSelectedInventoryItem({}));
      dispatch(fetchActiveStakes());
      dispatch(fetchInventory());
    };

    return await HTTPService.post(accountUseInventoryItemAPI, {
      inventoryId,
      stakeId,
    })
      .then((res) => {
        if (res.status === 1 && res.data) {
          toast.success(
            `Successfully used ${
              state.inventory.selected?.item?.name
            } on nest ${state.nest.activeNest?.toUpperCase()}`,
            { duration: 70000 }
          );
          refreshInventory();
          return res.data;
        } else if (res.status === 0 && res.errors) {
          toast.error(res.errors?.[0], { duration: 7000 });
        }
      })
      .catch((e) => {
        refreshInventory();
        toast.error(
          `Failed to use the ${state.inventory.selected?.item?.name}`,
          { duration: 70000 }
        );
        return thunkAPI.rejectWithValue(e);
      });
  }
);

export const inventoryPotionsSlice = createSlice({
  name: "inventory",
  initialState: {
    inventoryLoading: false,
    inventory: [],
    selected: {},
    usePotionModalVisible: false,
    useInventoryItemLoading: false,
  },
  reducers: {
    setSelectedInventoryItem: (state, action) => {
      state.selected = action.payload;
    },
    setUsePotionModalVisibility: (state, action) => {
      state.usePotionModalVisible = action.payload;
    },
  },
  extraReducers: {
    [fetchInventory.fulfilled]: (state, action) => {
      state.inventory = action.payload;
      state.inventoryLoading = false;
    },
    [fetchInventory.pending]: (state) => {
      state.inventoryLoading = true;
    },
    [fetchInventory.rejected]: (state) => {
      state.inventoryLoading = false;
    },
    [useInventoryPotionOnSelectedNest.fulfilled]: (state) => {
      state.useInventoryItemLoading = false;
    },
    [useInventoryPotionOnSelectedNest.rejected]: (state) => {
      state.useInventoryItemLoading = false;
    },
    [useInventoryPotionOnSelectedNest.pending]: (state) => {
      state.useInventoryItemLoading = true;
    },
  },
});

export const { setSelectedInventoryItem, setUsePotionModalVisibility } =
  inventoryPotionsSlice.actions;

export default inventoryPotionsSlice.reducer;
