import React from "react";
import search from "../../assets/images/search.png";
import styles from "./HistorySearchInput.module.scss";

const HistorySearchInput = () => {
  return (
    <div className={styles.searchInput}>
      <input
        type="text"
        id="name_field"
        className="custom-input mb-2"
        name="title"
        maxLength="50"
      />
      <button className={styles.searchicon}>
        <img src={search} className="img-fluid" alt="search" />
      </button>
    </div>
  );
};

export default HistorySearchInput;
