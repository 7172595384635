import classNames from "classnames";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  buyNFT,
  buyPotion,
  sendToTimeMachine,
} from "../../common/api-endpoints";
import HTTPService from "../../common/httpService";
import OpenInXumOrNewWindowModal from "../../components/OpenInXumOrNewWindowModal";
import useShopPageWebHook, {
  XUM_TRANSACTION_STATES,
} from "./hook/useShopPageWebHook";
import SendToTimeMachineStatusPopup from "./SendToTimeMachineStatusPopup";

const TimeMachineItem = ({ background, item }) => {
  const { listenToPurchaseSocket, transactionStatus, closeSocket } =
    useShopPageWebHook();

  const [isLoading, setIsLoading] = useState(false);
  const [openXumModal, setOpenXumModal] = useState(false);
  const [txStatusModal, setTxStatusModal] = useState(false);
  const [xummModalContent, setXummModalContent] = useState({});

  // once user click on x button in xum modal he will lost access to socket
  // make sure he sees right message as per his payment.
  const closeXumModalAndCloseSocket = () => {
    closeSocket();
    setOpenXumModal(false);
    setTxStatusModal(false);
  };
  // set info popup on tx state change
  useEffect(() => {
    if (transactionStatus !== XUM_TRANSACTION_STATES.INIT) {
      setTxStatusModal(true);
      setOpenXumModal(false);
    } else {
      setTxStatusModal(false);
    }
  }, [transactionStatus]);

  const handleSendToTimeMachine = () => {
    setIsLoading(true);
    if (item.NFTokenID) {
      HTTPService.post(sendToTimeMachine, { NFTokenID: item.NFTokenID })
        .then((res) => {
          setIsLoading(false);
          if (res.status === 1 && res.data) {
            setOpenXumModal(true);
            setXummModalContent({
              xummLink: res.data.next.always,
              qrCodeImage: res.data.refs.qr_png,
            });
            listenToPurchaseSocket(res.data.refs.websocket_status);
          }

          if (res.status === 0 && res.message) {
            toast.error(res.message);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e.message);
        });
    }
  };
  return (
    <>
      <div className={`eagle-block ${background}`}>
        <h6 className="tx-sixth shop-nft-title text-center  f-700">
          {/* Flags: {item.Flags} */}
        </h6>
        <div className="img-eagle-wrap">
          <img src={item.imgUrl} className="img-fluid eagle-img" alt="eagle" />
        </div>
        <div className="ct-block-2">
          <h4 className="tx-sixth e-title">{item.nftJson.name} </h4>
          <h6 className="tx-sixth e-sub-title">{item.nftJson.description}</h6>
          <div className="d-flex align-items-center w-100">
            <div className="price-block">
              <div className="block-1">
                <h6 className="tx-sixth  mb-0 f-400 second">{item.currency}</h6>
              </div>
              <div className="block-2">
                <h6 className="tx-sixth mb-0  f-700">{item.secPrice}</h6>
              </div>
            </div>
            <div>
              <button
                className={classNames("mt-0 nes-btn is-error", {
                  "is-disabled": isLoading,
                })}
                onClick={() => {
                  handleSendToTimeMachine();
                }}
              >
                {isLoading ? "Sending..." : "Send To Time Machine"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <OpenInXumOrNewWindowModal
        xummLink={xummModalContent.xummLink}
        qrCodeImage={xummModalContent.qrCodeImage}
        closeModal={closeXumModalAndCloseSocket}
        modalIsOpen={openXumModal}
      />
      <SendToTimeMachineStatusPopup
        isOpen={txStatusModal}
        purchaseStatus={transactionStatus}
        onOk={() => {
          setTxStatusModal(false);
        }}
      />
    </>
  );
};

export default TimeMachineItem;
