import React from "react";
import AvailableEaglsInventoryDesktop from "./inventory/AvailableEaglsInventoryDesktop";
import AvailableEaglsInventoryMobile from "./inventory/AvailableEaglsInventoryMobile";
import "./Inventory.scss";

const Inventory: React.FC = () => {
  return (
    <React.Fragment>
      <AvailableEaglsInventoryDesktop />
      <AvailableEaglsInventoryMobile />
    </React.Fragment>
  );
};

export default Inventory;
