import classNames from "classnames";
import SeoMetaTags from "../../components/SeoMetaTags";
import HistoryTabs from "./HistoryTabs";
import styles from "./History.module.scss";
import HistoryPageTitle from "./HistoryPageTitle";

const History = () => {
  return (
    <div className={classNames("common-lap", styles.historyWrapper)}>
      <SeoMetaTags title={"History"} />
      <HistoryPageTitle />
      <HistoryTabs />
    </div>
  );
};

export default History;
