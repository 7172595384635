import moment from "moment";
import React, { useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import border from "../assets/images/border.png";
import trophy from "../assets/images/trophy.png";
import "../assets/scss/components/rewardBlockModal.scss";
import { rewardsMint } from "../common/api-endpoints";
import HTTPService from "../common/httpService";

const RewardBlock = ({ reward }) => {
  const { nfts } = useSelector((state) => state.nft);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [apiloading, setApiLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const closeModal = () => setModalIsOpen(false);

  const mintReward = () => {
    setApiLoading(true);
    setModalIsOpen(true);

    HTTPService.post(rewardsMint, { rewardId: reward._id })
      .then((result) => {
        setApiLoading(false);
        setIsError(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err) => {
        setIsError(true);
        setApiLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };
  return (
    <React.Fragment>
      <div className="main-block">
        <div className="inner">
          <div className="first-block-wrapper">
            <div className="first-text-blk">
              <div className="bird-wrapper-block">
                <img
                  src={reward.staking.images[0]}
                  alt="first eagle"
                  className="img-fluid kg-1"
                />
                <img
                  src={reward.staking.images[1]}
                  style={{ transform: "rotateY(180deg)" }}
                  alt="second eagle"
                  className="img-fluid kg-2"
                />
              </div>
              <h6 className="tx-sixth f-400">
                {moment(reward.staking.timeline[0].time).format("Do MMM YY")}
              </h6>
            </div>
            <div className="text-blk">
              {nfts.map((nft, i) => {
                if (nft.stakeValue === reward.staking.tokens[0]) {
                  return (
                    <p key={i} className="tx-sixth mb-0">
                      {nft.name}
                    </p>
                  );
                }
                if (nft.stakeValue === reward.staking.tokens[1]) {
                  return (
                    <p key={i} className="tx-sixth mb-0">
                      {nft.name}
                    </p>
                  );
                }
              })}
            </div>
          </div>
          <div className="duration-wrapper-block">
            <h6 className="tx-sixth f-400">Duration</h6>
            <div className="duration-block">
              <h2 className="mb-0">
                {reward.staking.duration}
                <span>:</span>00<span>:</span>00
              </h2>
            </div>
            <div className="duration-block-timer">
              <h5>Days</h5>
              <h5>Hours</h5>
              <h5>Mins</h5>
            </div>
          </div>
          <div className="position-used">
            <h6 className="tx-sixth f-400">Potions Used</h6>
          </div>
          <div className="bird-trophy-block">
            <div className="bird-reward">
              <img src={reward.imgUrl} alt="baby eagle" className="img-fluid" />
              <div className="trophy-block">
                <img src={trophy} className="img-fluid" alt="icon" />
              </div>
            </div>
            <div className="border-block">
              <img src={border} className="img-fluid img-1" alt="border" />
            </div>
          </div>
        </div>
        {reward.isMinted ? (
          <button className="custom-button mt-0 nes-btn is-error" disabled>
            Minted
          </button>
        ) : (
          <button
            className="custom-button mt-0 nes-btn is-error"
            onClick={mintReward}
          >
            Mint
          </button>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Open In XUMM modal"
        className="reward-mint-modal"
        overlayClassName="reward-mint-modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="inner-wrapper">
          {!apiloading ? (
            <div className="close-button" onClick={closeModal}>
              X
            </div>
          ) : null}
          {apiloading ? (
            <div className="loading-state">
              <p>Minting is in progress, please do not leave this page.</p>
              <div className="loader"></div>
            </div>
          ) : isError ? (
            <p>Failed to Mint.</p>
          ) : (
            <p>Your reward minted successfully.</p>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default RewardBlock;
