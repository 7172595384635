import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "../assets/scss/pages/swap.scss";
import { swapNFTAPI, swapRequestNFTAPI } from "../common/api-endpoints";
import HTTPService from "../common/httpService";
import { Loader } from "../components";
import OpenInXumOrNewWindowModal from "../components/OpenInXumOrNewWindowModal";
import SeoMetaTags from "../components/SeoMetaTags";
import SwapEagleItem from "../components/swapEagleItem";
import useShopPageWebHook, {
  XUM_TRANSACTION_STATES,
} from "./shop/hook/useShopPageWebHook";
import ShopPurchaseStatusPopup from "./shop/ShopPurchaseStatusPopup";

const Swap = () => {
  const {
    listenToPurchaseSocket,
    transactionId,
    transactionStatus,
    closeSocket,
  } = useShopPageWebHook();

  const [loading, setLoading] = useState(false);
  const [swaps, setSwaps] = useState([]);

  useEffect(() => {
    setLoading(true);
    HTTPService.get(swapNFTAPI)
      .then((response) => {
        setLoading(false);
        setSwaps(response.data.nft);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.statusText);
      });
  }, []);

  const [openXumModal, setOpenXumModal] = useState(false);
  const [xummModalContent, setXummModalContent] = useState({});
  const [swapLoading, setSwapLoading] = useState([]);
  const [txStatusModal, setTxStatusModal] = useState(false);

  const closeXumModalAndCloseSocket = () => {
    closeSocket();
    setOpenXumModal(false);
    setTxStatusModal(false);
  };

  useEffect(() => {
    if (transactionStatus !== XUM_TRANSACTION_STATES.INIT) {
      setTxStatusModal(true);
      setOpenXumModal(false);
    } else {
      setTxStatusModal(false);
    }
  }, [transactionStatus]);

  const handleSwap = (currency, issuer) => {
    let index = swaps.findIndex(
      (swap) => swap.issuer === issuer && swap.currency === currency
    );
    setSwapLoading((prev) => {
      let loadingState = [...prev];
      loadingState[index] = true;
      return loadingState;
    });
    HTTPService.post(swapRequestNFTAPI, { currency, issuer })
      .then((response) => {
        setOpenXumModal(true);
        setXummModalContent({
          xummLink: response.data.nftsOwned.next.always,
          qrCodeImage: response.data.nftsOwned.refs.qr_png,
        });
        setSwapLoading((prev) => {
          let loadingState = [...prev];
          loadingState[index] = false;
          return loadingState;
        });
        listenToPurchaseSocket(response.data.nftsOwned.refs.websocket_status);
      })
      .catch((err) => {
        setSwapLoading((prev) => {
          let loadingState = [...prev];
          loadingState[index] = false;
          return loadingState;
        });
      });
  };

  const divideBy = 0.000000000000000000000000000000000000000000000000000000000000000000000000000000001;

  return (
    <React.Fragment>
      <SeoMetaTags title={"swap"} />
      {loading ? (
        <Loader />
      ) : (
        <div className="common-lap swap-main-wrapper">
          <div className="title-block">
            <h3 className="tx-twelve">Swap</h3>
          </div>
          <div className="common-inner-lap">
            <div className="eagle-shop">
              {swaps.map((swap, index) => {
                let eagleName = swap.name;
                if (swap.name === "SECWarriorClub")
                  eagleName = `${eagleName} X ${Number(swap.value) / divideBy}`;
                return (
                  <SwapEagleItem
                    currency={swap.currency}
                    eagleImage={swap.image}
                    eagleName={eagleName}
                    issuer={swap.issuer}
                    handleClick={handleSwap}
                    isLoading={swapLoading[index]}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
      <OpenInXumOrNewWindowModal
        xummLink={xummModalContent.xummLink}
        qrCodeImage={xummModalContent.qrCodeImage}
        closeModal={closeXumModalAndCloseSocket}
        modalIsOpen={openXumModal}
      />
      <ShopPurchaseStatusPopup
        isOpen={txStatusModal}
        purchaseStatus={transactionStatus}
        onOk={() => {
          setTxStatusModal(false);
        }}
      />
    </React.Fragment>
  );
};

export default Swap;
