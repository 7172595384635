import { useState } from "react";
import HTTPService from "../common/httpService";
import { accountNFTAPI } from "../common/api-endpoints";

const Alias = ({ stakeValue, rotating, hanldleOnClick }) => {
  const [aliasName, setAliasName] = useState("");

  const handleOnEnter = (e) => {
    if (e.key === "Enter") {
      HTTPService.post(`${accountNFTAPI}/${stakeValue}`, { aliasName })
        .then((res) => {
          hanldleOnClick(!rotating);
        })
        .catch((e) => {
          console.log("Error", e);
        });
    }
  };

  return (
    <>
      <div className="nes-field name-input">
        <div className="cls-input">
          <input
            type="text"
            placeholder="Set alias"
            className="nes-input"
            value={aliasName}
            onChange={(e) => setAliasName(e.target.value)}
            onKeyDown={(e) => handleOnEnter(e)}
            style={{ transform: rotating ? "rotateY(180deg)" : "" }}
          />
        </div>
      </div>
    </>
  );
};

export default Alias;
