import classNames from "classnames";
import React, { useState } from "react";
import BirdsList from "./BirdsList";
import { INVENTORY_TABS } from "./constants";
import InventoryPotions from "./InventoryPotions";
import NestsList from "./NestsList";

const AvailableEaglsInventoryDesktop: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>(INVENTORY_TABS.NFT);

  return (
    <div className="nfy-block desktop">
      <div
        className="d-flex gap-2 inventory-tab"
        onClick={(e) => {
          const tabName = (e.target as HTMLInputElement).getAttribute(
            "data-name"
          ) as string;
          if (tabName) {
            setActiveTab(tabName);
          }
        }}
      >
        <h3
          className={classNames({ active: activeTab === INVENTORY_TABS.NFT })}
          data-name={INVENTORY_TABS.NFT}
        >
          NFTs
        </h3>
        <h3
          className={classNames({ active: activeTab === INVENTORY_TABS.NEST })}
          data-name={INVENTORY_TABS.NEST}
        >
          Nests
        </h3>
        <h3
          className={classNames({
            active: activeTab === INVENTORY_TABS.INVENTORY,
          })}
          data-name={INVENTORY_TABS.INVENTORY}
        >
          Inventory
        </h3>
      </div>
      {activeTab === INVENTORY_TABS.NFT && <BirdsList />}
      {activeTab === INVENTORY_TABS.NEST && <NestsList />}
      {activeTab === INVENTORY_TABS.INVENTORY && <InventoryPotions />}
    </div>
  );
};

export default AvailableEaglsInventoryDesktop;
