import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  otherTransactionsSortedInDescOrderByDateSelector,
  stakeRewardTransactionsSortedInDescOrderByDateSelector,
  userTransactionSelectors,
} from "../../store/selectors";
import { fetchAllTransactions } from "../../store/transactionSlice";
import FilterTransactions from "./FilterTransactions";
import TransactionItem from "./TransactionItem";

export const FILTER_TYPES = {
  TRANSACTION: "TRANSACTION",
  REWARD: "REWARD",
};

const Transactions = ({ bgColor }) => {
  const dispatch = useDispatch();

  const { transactions, loading } = useSelector(userTransactionSelectors);
  const stakeRewardTransactions = useSelector(
    stakeRewardTransactionsSortedInDescOrderByDateSelector
  );
  const otherTransactions = useSelector(
    otherTransactionsSortedInDescOrderByDateSelector
  );

  useEffect(() => {
    dispatch(fetchAllTransactions());
  }, [dispatch]);

  const [filterType, setFilterType] = useState(FILTER_TYPES.REWARD);

  const filteredTransactions = useMemo(() => {
    if (filterType === FILTER_TYPES.REWARD) return stakeRewardTransactions;
    else return otherTransactions;
  }, [filterType, otherTransactions, stakeRewardTransactions]);

  return (
    <>
      <FilterTransactions
        setFilterType={setFilterType}
        filterType={filterType}
      />

      {transactions?.length > 0 ? (
        filteredTransactions.map((transaction) => {
          return (
            <TransactionItem
              bgColor={bgColor}
              transaction={transaction}
              key={transaction.id}
            />
          );
        })
      ) : loading ? (
        <p>Loading........</p>
      ) : (
        <p>No Transactions Found!</p>
      )}
    </>
  );
};

export default Transactions;
