import React from "react";
import { Helmet } from "react-helmet-async";

const SeoMetaTags = ({ title = "" }) => {
  return (
    <Helmet>
      <title>Super Eagle | {title}</title>
      <link rel="canonical" href={window.location.host} />
    </Helmet>
  );
};

export default SeoMetaTags;
