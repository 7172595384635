import classNames from "classnames";
import React, { useState } from "react";
import arrow from "../../../assets/images/up-arrow.png";
import BirdsList from "./BirdsList";
import { INVENTORY_TABS } from "./constants";
import InventoryPotions from "./InventoryPotions";
import NestsList from "./NestsList";

const AvailableEaglsInventoryMobile: React.FC = () => {
  const [dropDown, setDropDown] = useState(false);
  const handleDropDown = () => {
    setDropDown(!dropDown);
  };

  const [activeTab, setActiveTab] = useState<string>(INVENTORY_TABS.NFT);
  return (
    <div className="custom-nft-drop">
      <button className="d-flex" onClick={handleDropDown}>
        <img src={arrow} className="img-fluid" alt="arrow" />
        <h3 className="tx-fifth cont">NFTS</h3>
        <img src={arrow} className="img-fluid" alt="arrow" />
      </button>
      <div className={`menu-it ${dropDown ? "d-block" : "d-none"}`}>
        <div className="nfy-block ipad">
          <div
            className="d-flex gap-2 inventory-tab"
            onClick={(e) => {
              const tabName = (e.target as HTMLInputElement).getAttribute(
                "data-name"
              ) as string;
              if (tabName) {
                setActiveTab(tabName);
              }
            }}
          >
            <h3
              className={classNames({
                active: activeTab === INVENTORY_TABS.NFT,
              })}
              data-name={INVENTORY_TABS.NFT}
            >
              NFTs
            </h3>
            <h3
              className={classNames({
                active: activeTab === INVENTORY_TABS.NEST,
              })}
              data-name={INVENTORY_TABS.NEST}
            >
              Nests
            </h3>
            <h3
              className={classNames({
                active: activeTab === INVENTORY_TABS.INVENTORY,
              })}
              data-name={INVENTORY_TABS.INVENTORY}
            >
              Inventory
            </h3>
          </div>

          {activeTab === INVENTORY_TABS.NFT && <BirdsList />}
          {activeTab === INVENTORY_TABS.NEST && <NestsList />}
          {activeTab === INVENTORY_TABS.INVENTORY && <InventoryPotions />}
        </div>
      </div>
    </div>
  );
};

export default AvailableEaglsInventoryMobile;
