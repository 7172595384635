import { useEffect, useState } from "react";
import "../assets/scss/components/nest.scss";
import egg from "../assets/images/egg.gif";
import eggCrack from "../assets/images/egg-crack.gif";
import eagleHatching from "../assets/images/eagle-hatching.gif";
import eagleHatched from "../assets/images/eagle-hatched.gif";
import alias from "../assets/images/alias.png";
import Alias from "./Alias";
import { useDispatch, useSelector } from "react-redux";
import { setActiveNest } from "../store/nestSlice";

const Nest = ({
  firstBird,
  secondBird,
  duration,
  remainHours,
  nestName = "s",
}) => {
  const dispatch = useDispatch();

  const { activeNest, activeNestWrapper } = useSelector((state) => state.nest);
  const quarter = parseInt((duration * 24) / 4);
  const elapsedTime = parseInt(duration * 24 - remainHours);

  const [firstAlias, setFirstAlias] = useState(false);
  const [secondAlias, setSecondAlias] = useState(false);

  const hanldleOnClick = (first) => {
    if (first) {
      setFirstAlias(!firstAlias);
      if (secondAlias) {
        setSecondAlias(!secondAlias);
      }
    } else {
      setSecondAlias(!secondAlias);
      if (firstAlias) {
        setFirstAlias(!firstAlias);
      }
    }
  };

  const handleSetActiveNest = () => {
    dispatch(setActiveNest(nestName));
  };

  // reset nest on wrapper change
  useEffect(() => {
    if (activeNest) {
      dispatch(setActiveNest(""));
    }
  }, [activeNestWrapper, dispatch]);

  return (
    <div className="nest-wrapper cursor-pointer" onClick={handleSetActiveNest}>
      {firstBird && (
        <div className="bird-img-wrapper">
          {firstBird.aliasName !== "NA" && (
            <div className="eagle-1">
              <img src={alias} className="img-fluid" alt="alias" />
              <span>{firstBird.aliasName}</span>
            </div>
          )}
          <img
            src={firstBird.url}
            className="img-fluid"
            alt="bird"
            onClick={() => hanldleOnClick(true)}
          />
          {firstAlias && (
            <Alias
              stakeValue={firstBird.stakeValue}
              rotating={false}
              hanldleOnClick={hanldleOnClick}
            />
          )}
        </div>
      )}
      {secondBird && (
        <>
          {elapsedTime <= quarter && (
            <div className="eag">
              <img src={egg} className="img-fluid" alt="eag" />
            </div>
          )}
          {elapsedTime > quarter && elapsedTime <= quarter * 2 && (
            <div className="eag">
              <img src={eggCrack} className="img-fluid" alt="eag" />
            </div>
          )}
          {elapsedTime > quarter * 2 && elapsedTime <= quarter * 3 && (
            <div className="eag">
              <img src={eagleHatching} className="img-fluid" alt="eag" />
            </div>
          )}
          {elapsedTime > quarter * 3 && elapsedTime <= quarter * 4 && (
            <div className="eag">
              <img src={eagleHatched} className="img-fluid" alt="eag" />
            </div>
          )}
          <div className="bird-img-wrapper rotate-left ">
            {secondBird.aliasName !== "NA" && (
              <div className="eagle-2">
                <img src={alias} className="img-fluid" alt="alias" />
                <span>{secondBird.aliasName}</span>
              </div>
            )}
            <img
              src={secondBird.url}
              className="img-fluid"
              alt="bird"
              onClick={() => hanldleOnClick(false)}
            />
            {secondAlias && (
              <Alias
                stakeValue={secondBird.stakeValue}
                rotating={true}
                hanldleOnClick={hanldleOnClick}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Nest;
