import classNames from "classnames";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { buyNFT, buyPotion } from "../../common/api-endpoints";
import HTTPService from "../../common/httpService";
import OpenInXumOrNewWindowModal from "../../components/OpenInXumOrNewWindowModal";
import useShopPageWebHook, {
  XUM_TRANSACTION_STATES,
} from "./hook/useShopPageWebHook";
import ShopPurchaseStatusPopup from "./ShopPurchaseStatusPopup";

const ShopPageItem = ({ background, item }) => {
  const { listenToPurchaseSocket, transactionStatus, closeSocket } =
    useShopPageWebHook();

  const [isLoading, setIsLoading] = useState(false);
  const [openXumModal, setOpenXumModal] = useState(false);
  const [txStatusModal, setTxStatusModal] = useState(false);
  const [xummModalContent, setXummModalContent] = useState({});

  // once user click on x button in xum modal he will lost access to socket
  // make sure he sees right message as per his payment.
  const closeXumModalAndCloseSocket = () => {
    closeSocket();
    setOpenXumModal(false);
    setTxStatusModal(false);
  };
  // set info popup on tx state change
  useEffect(() => {
    if (transactionStatus !== XUM_TRANSACTION_STATES.INIT) {
      setTxStatusModal(true);
      setOpenXumModal(false);
    } else {
      setTxStatusModal(false);
    }
  }, [transactionStatus]);

  const handleNFTBuy = (isItemPurchasable) => {
    if (isItemPurchasable) {
      setIsLoading(true);
      if (item.isNFT) {
        HTTPService.get(buyNFT + item.id)
          .then((res) => {
            setIsLoading(false);
            if (res.status === 1 && res.data) {
              setOpenXumModal(true);
              setXummModalContent({
                xummLink: res.data.next.always,
                qrCodeImage: res.data.refs.qr_png,
              });
              listenToPurchaseSocket(res.data.refs.websocket_status);
            }
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error(e.message);
          });
      } else {
        HTTPService.post(buyPotion, { itemId: item.id })
          .then((res) => {
            setIsLoading(false);
            if (res.status === 1 && res.data) {
              setOpenXumModal(true);
              setXummModalContent({
                xummLink: res.data.xummResponse.next.always,
                qrCodeImage: res.data.xummResponse.refs.qr_png,
              });
              listenToPurchaseSocket(
                res.data.xummResponse.refs.websocket_status
              );
            } else if (res.status === 0 && res.errors) {
              toast.error(res.errors?.[0], { duration: 7000 });
            }
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error(e.message);
          });
      }
    }
  };
  return (
    <>
      <div
        className={`eagle-block ${background} ${
          !item.isEnable ? "disabled" : ""
        }`}
      >
        <h6 className="tx-sixth shop-nft-title text-center  f-700">
          {item?.extra?.sold >= 0
            ? `NFTs sold: ${item.extra.sold}/${item.extra.total}`
            : `Qty: 0`}
        </h6>
        <div className="img-eagle-wrap">
          <img src={item.image} className="img-fluid eagle-img" alt="eagle" />
        </div>
        <div className="ct-block-2">
          <h4 className="tx-sixth e-title">{item.name} </h4>
          <h6 className="tx-sixth e-sub-title">{item.description}</h6>
          <div className="d-flex align-items-center w-100">
            <div className="price-block">
              <div className="block-1">
                <h6 className="tx-sixth  mb-0 f-400 second">{item.currency}</h6>
              </div>
              <div className="block-2">
                <h6 className="tx-sixth mb-0  f-700">{item.secPrice}</h6>
              </div>
            </div>
            <div>
              <button
                className={classNames("mt-0 nes-btn is-error", {
                  "is-disabled": isLoading,
                })}
                onClick={() => {
                  const itemIsAvailableToBuy =
                    Number(item.extra?.sold) < Number(item.extra?.total) ||
                    true;
                  handleNFTBuy(itemIsAvailableToBuy);
                }}
              >
                {isLoading ? "Buy..." : "Buy"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <OpenInXumOrNewWindowModal
        xummLink={xummModalContent.xummLink}
        qrCodeImage={xummModalContent.qrCodeImage}
        closeModal={closeXumModalAndCloseSocket}
        modalIsOpen={openXumModal}
      />
      <ShopPurchaseStatusPopup
        isOpen={txStatusModal}
        purchaseStatus={transactionStatus}
        onOk={() => {
          setTxStatusModal(false);
        }}
      />
    </>
  );
};

export default ShopPageItem;
