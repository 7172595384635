import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import coin from "../assets/images/coin.gif";
import logo from "../assets/images/logo.png";
import notificationIcon from "../assets/images/notification.png";
import profile from "../assets/images/user.png";
import "../assets/scss/components/header.scss";
import { ClearNotificationSVG } from "../assets/svg/ClearNotification";
import { SoundOffSVG, SoundOnSVG } from "../assets/svg/SoundIcon";
import {
  clearNotificationAPI,
  getNotificationsAPI,
  markReadNotificationAPI,
} from "../common/api-endpoints";
import HTTPService from "../common/httpService";
import { logout } from "../store/authSlice";
import ActiveLink from "./ActiveLink";

const Header = ({ handleMusic }) => {
  const dispatch = useDispatch();
  const popupRef = useRef();
  const notificationRef = useRef();
  const { user, isMusicPlaying } = useSelector((state) => state.auth);
  const [dropdown, setDropdown] = useState(false);
  const [responsive, setResponsive] = useState(false);
  const [settings, setSettings] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        settings &&
        popupRef.current &&
        !popupRef.current.contains(e.target)
      ) {
        handleSettings();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [settings]);

  useEffect(() => {
    const checkIfClickedOutsideDropdown = (e) => {
      if (
        dropdown &&
        notificationRef.current &&
        !notificationRef.current.contains(e.target)
      ) {
        handleDropdown();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideDropdown);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutsideDropdown);
    };
  }, [dropdown]);

  const handleDropdown = () => {
    setDropdown(!dropdown);
    if (!dropdown) {
      if (notifications.filter((n) => !n.isRead).length) {
        HTTPService.get(markReadNotificationAPI)
          .then((res) => {
            setCount(0);
          })
          .catch((e) => {
            console.log("Error", e);
          });
      }
    }
  };
  const handleResponsive = () => {
    setResponsive(!responsive);
  };

  const handleSettings = () => {
    setSettings(!settings);
  };

  const playMusic = (event) => {
    event.preventDefault();
    handleMusic();
  };

  const handleLogout = () => {
    dispatch(logout())
      .then(() => {
        localStorage.clear();
        window.location.reload();
      })
      .catch((e) => {
        console.log("Error", e);
        window.location.reload();
      });
  };

  const handlleClearNotification = () => {
    HTTPService.get(clearNotificationAPI)
      .then((res) => {
        handleDropdown();
        setCount(0);
        getNotifications();
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  const getNotifications = useCallback(() => {
    HTTPService.get(getNotificationsAPI)
      .then((res) => {
        if (res.status === 1) {
          const notify = [];
          for (let i = 0; i < res.data.results.length; i++) {
            notify.push(res.data.results[i]);
            if (!res.data.results[i].isRead) {
              setCount(count + 1);
            }
          }
          setNotifications(notify);
        }
      })
      .catch((e) => {
        // console.log("Error", e);
      });
  }, []);

  return (
    <div className="header-wrapper">
      <button className="burger-btn" onClick={handleResponsive}>
        <svg
          width="30"
          height="34"
          viewBox="0 0 30 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.863249 17L3.75 19.8868L6.63675 17L3.75 14.1132L0.863249 17ZM29.1368 17L26.25 14.1132L23.3632 17L26.25 19.8868L29.1368 17ZM3.75 17.5H26.25V16.5H3.75V17.5Z"
            fill="#023047"
          />
          <path
            d="M0.863249 8.5L3.75 11.3868L6.63675 8.5L3.75 5.61325L0.863249 8.5ZM29.1368 8.5L26.25 5.61325L23.3632 8.5L26.25 11.3868L29.1368 8.5ZM3.75 9H26.25V8H3.75V9Z"
            fill="#023047"
          />
          <path
            d="M0.863249 25.5L3.75 28.3868L6.63675 25.5L3.75 22.6132L0.863249 25.5ZM29.1368 25.5L26.25 22.6132L23.3632 25.5L26.25 28.3868L29.1368 25.5ZM3.75 26H26.25V25H3.75V26Z"
            fill="#023047"
          />
        </svg>
      </button>
      <Link to="/">
        <div className="logo">
          <img className="img-fluid logo" src={logo} alt="logo" />
        </div>
      </Link>
      <ul className="page-link">
        <div className="right-link-wrapper">
          <div className="link-wrapper mobile-hide">
            <a
              href="https://supereaglebucket.s3.amazonaws.com/donwloads/Supereagle-Final.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Lite Paper
            </a>
          </div>
          <div className="link-wrapper mobile-hide">
            <NavLink to={"/history"}>History</NavLink>
          </div>
          <div className="link-wrapper mobile-hide">
            <NavLink to={"/rewards"}>Rewards</NavLink>
          </div>
          <div className="link-wrapper mobile-hide">
            <NavLink to={"/credits"}>Credits</NavLink>
          </div>
          <div className="link-wrapper">
            <div className="coin-box">
              <div className="counter-box">
                {user.coins ? parseFloat(user.coins).toFixed(2) : 0}
              </div>
              <div className="coin-img">
                <img src={coin} alt="coin" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="link-wrapper mobile-hide">
            <div
              className={`notification ${dropdown ? "open" : ""}`}
              ref={notificationRef}
            >
              <button className="notification-button" onClick={handleDropdown}>
                <img
                  src={notificationIcon}
                  className="img-fluid nt-icon"
                  alt="nt-icon"
                />
                <div className="round-wrap">{count}</div>
              </button>
              <div className="notification-menu">
                <ul className="notification-container">
                  <li className="nt-header special">Notification</li>
                  {notifications.map((n, i) => (
                    <li key={i} className="nt-header">
                      {`${n.title} ${n.message}`}
                    </li>
                  ))}
                </ul>
                <Link to="#" className="clear-notification">
                  {ClearNotificationSVG}
                  <div className="l-content" onClick={handlleClearNotification}>
                    Clear notification
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="link-wrapper mobile-hide position-relative">
            <button className="userlink" onClick={handleSettings}>
              <img
                src={user.profilePic ? user.profilePic : profile}
                alt="user"
                className="profile"
              />
            </button>
            <div
              className={`userDropdownMenu ${settings ? "open" : ""}`}
              ref={popupRef}
            >
              <ul className="menu-op">
                <li>
                  <Link
                    to="#"
                    className="item"
                    style={{
                      display: "inline-block",
                      maxWidth: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {user.xrpAddress}
                  </Link>
                </li>
                <li>
                  <Link to="#" className="item" onClick={(e) => playMusic(e)}>
                    Sound {isMusicPlaying ? SoundOnSVG : SoundOffSVG}
                  </Link>
                </li>
                <li>
                  <Link to="#" className="item" onClick={handleLogout}>
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ul>
      <div
        className={`backdrop ${responsive ? "open" : ""}`}
        onClick={handleResponsive}
      />
      <div
        className={`responsive-header ${responsive ? "open" : ""}`}
        onClick={handleResponsive}
      >
        <div className="user-block">
          <div className="user-first">
            <img
              src={user.profilePic ? user.profilePic : profile}
              alt="user"
              className="profile"
            />
          </div>
          <div className="user-content-block">
            <h3 className="tx-white">{user.name}</h3>
            <h5 className="third-font tx-white">{user.email}</h5>
          </div>
        </div>
        <div className="divider" />
        <ul className="page-links">
          <li>
            <NavLink to={"#"} className="r-link">
              <div className="icon-wr"></div>
              <div className="content-wrap-box">
                <h6
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {user.xrpAddress}
                </h6>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/notifications"} className="r-link active">
              <div className="icon-wr">
                <ActiveLink count={count} />
              </div>
              <div className="content-wrap-box">Notifications</div>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/swap"} className="r-link">
              <div className="icon-wr"></div>
              <div className="content-wrap-box">Swap</div>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/withdraw"} className="r-link">
              <div className="icon-wr"></div>
              <div className="content-wrap-box">Withdraw</div>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/shop"} className="r-link">
              <div className="icon-wr"></div>
              <div className="content-wrap-box">Shop</div>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/time-machine"} className="r-link">
              <div className="icon-wr"></div>
              <div className="content-wrap-box">Time Machine</div>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/rewards"} className="r-link">
              <div className="icon-wr"></div>
              <div className="content-wrap-box">Rewards</div>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/history"} className="r-link">
              <div className="icon-wr"></div>
              <div className="content-wrap-box">History</div>
            </NavLink>
          </li>
          <li>
            <div className="r-link">
              <div className="icon-wr"></div>
              <div className="content-wrap-box">
                {" "}
                <a
                  href="https://supereaglebucket.s3.amazonaws.com/donwloads/Supereagle-Final.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="r-link"
                >
                  Lite Paper
                </a>
              </div>
            </div>
          </li>
          <li>
            <NavLink to={"#"} className="r-link" onClick={(e) => playMusic(e)}>
              <div className="icon-wr"></div>
              <div className="content-wrap-box">
                Sound {isMusicPlaying ? SoundOnSVG : SoundOffSVG}
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to={"#"} className="r-link" onClick={handleLogout}>
              <div className="icon-wr"></div>
              <div className="content-wrap-box">Logout</div>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
