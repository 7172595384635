import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import stake from "../../assets/images/stake.png";
import { BugModal } from "../../components";
import { setUsePotionModalVisibility } from "../../store/inventoryPotionsSlice";
import { activeStakeNestSelector, selectedNestsNftSelector } from "./selector";

const StakeButton: React.FC<any> = ({ handleStakeClick }) => {
  const dispatch = useDispatch();

  const { nests, activeNest } = useSelector((state: any) => state.nest);
  const { canDoNewStake } = useSelector((state: any) => state.stake);
  const selectedInventoryItem = useSelector(
    (state: any) => state.inventory.selected
  );

  const activeStakesInCurrentWrapper = useSelector(activeStakeNestSelector);

  const selectedNestNfts = useSelector(selectedNestsNftSelector);
  const doIHaveEnoughNftsToStakeOrStop = selectedNestNfts?.length === 2;

  const [isBugModelVisible, setIsBugModelVisible] = useState(false);
  const setBugModelVisibility = () => setIsBugModelVisible(!isBugModelVisible);
  return (
    <React.Fragment>
      <div className="bottom-content">
        <div className="stack-wrap">
          <img
            src={stake}
            alt="stake"
            className="img-fluid"
            onClick={setBugModelVisibility}
          />
        </div>
        <div className="btn-c-wrapper mt-0">
          {nests.length &&
          !canDoNewStake &&
          activeNest &&
          activeStakesInCurrentWrapper.length &&
          doIHaveEnoughNftsToStakeOrStop ? (
            <button
              type="button"
              className="custom-btn  mt-0 nes-btn is-error"
              onClick={() => handleStakeClick(false)}
            >
              Stop
            </button>
          ) : (
            canDoNewStake &&
            doIHaveEnoughNftsToStakeOrStop && (
              <button
                type="button"
                className="custom-btn  mt-0 nes-btn is-error"
                onClick={() => handleStakeClick(true)}
              >
                Stake
              </button>
            )
          )}
          {selectedInventoryItem.id && (
            <button
              type="button"
              className="custom-btn  mt-0 nes-btn is-primary ms-2"
              onClick={() => {
                if (!activeNest) {
                  toast.error("Please select a Nest to use this item.");
                  return null;
                }
                dispatch(setUsePotionModalVisibility(true));
              }}
            >
              Use
            </button>
          )}
        </div>
      </div>
      {isBugModelVisible && <BugModal bugModal={setBugModelVisibility} />}
    </React.Fragment>
  );
};

export default StakeButton;
