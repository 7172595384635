import React from "react";
import { useSelector } from "react-redux";
// @ts-ignore
import { XCircle } from "feather-icons-react";
import nftLoaderGif from "../../../assets/images/nft-loader.gif";
import { activeStakeNestsCapacitySelector } from "../selector";
import useInventoryEagles from "./hook/useInventoryEagles";

const BirdsList: React.FC<any> = () => {
  const { handleInventoryEagleClick, selectedNft, removeEagleFromSelection } = useInventoryEagles();

  const isNestAvailableForStaking = useSelector(
    activeStakeNestsCapacitySelector
  );
  const {
    nft: { nfts, nftsLoading },
  } = useSelector((state: any) => state);

  const isNftAlreadySelected = (nft: any) =>
    !!selectedNft.filter(({ stakeValue }: any) => stakeValue === nft.stakeValue)
      .length;
  const heyAmIStakable = (nft: any) => {
    return (
      !nft.isStaked &&
      selectedNft.length < 2 &&
      isNestAvailableForStaking &&
      !isNftAlreadySelected(nft)
    );
  };

  return (
    <ul className="nft-ul">
      {nftsLoading ? (
        <img
          src={nftLoaderGif}
          alt="nftloading"
          style={{
            maxWidth: 100,
            margin: "auto",
          }}
        />
      ) : (
        nfts.map((nft: any, i: number) => (
          <div key={nft.stakeValue}>
            {isNftAlreadySelected(nft) && (
              <XCircle
                className="closeIcon"
                onClick={() => removeEagleFromSelection(nft)}
              />
            )}
            <li
              className={`tooltip ${
                heyAmIStakable(nft) ? "active cursor-pointer" : "in-active"
              } `}
            >
              <img
                src={nft.url}
                alt="kagdo"
                className="img-fluid "
                onClick={() => handleInventoryEagleClick(nft)}
              />
              <span className="tooltiptext">{nft.name}</span>
            </li>
          </div>
        ))
      )}
    </ul>
  );
};

export default BirdsList;
