import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HTTPService from "../common/httpService";
import { localStorageKeys } from "../common/constants";
import { loginAPI, verifyXUMMAPI } from "../common/api-endpoints";

const userLoggedIn = JSON.parse(
  localStorage.getItem(localStorageKeys.isLoggedIn)
);
const initialState = {
  isLoggedIn: userLoggedIn ? true : false,
  user: userLoggedIn ? userLoggedIn.user : null,
  isMusicPlaying: false,
};

export const verifyXUMM = createAsyncThunk(
  "verify",
  ({ payloadId }, thunkAPI) => {
    return HTTPService.post(verifyXUMMAPI, { payload_uuidv4: payloadId })
      .then((res) => {
        if (res.status === 1) {
          const data = {
            user: res.data.user,
            token: res.data.tokens.access.token,
          };
          return data;
        }
      })
      .catch(() => {
        return thunkAPI.rejectWithValue();
      });
  }
);

export const login = createAsyncThunk(
  "login",
  ({ xrpAddress, password }, thunkAPI) => {
    return HTTPService.post(loginAPI, { xrpAddress, password })
      .then((res) => {
        const data = {
          user: res.user,
          token: res.tokens.access.token,
        };
        return data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue();
      });
  }
);

export const logout = createAsyncThunk("logout", async () => {
  return true;
});
export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    updateUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    updateMusic: (state) => {
      state.isMusicPlaying = !state.isMusicPlaying;
    },
  },
  extraReducers: {
    [verifyXUMM.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [verifyXUMM.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

export const { updateUser, updateMusic } = authSlice.actions;

export default authSlice.reducer;
