export const SoundOffSVG = (
  <svg
    width="83"
    height="64"
    viewBox="0 0 115 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: "30px" }}
  >
    <rect
      width="50"
      height="40"
      transform="matrix(-1 0 0 1 58 24)"
      fill="#FF4345"
    />
    <rect
      width="26"
      height="8"
      transform="matrix(-1 0 0 1 58 16)"
      fill="#FF4345"
    />
    <rect
      width="18"
      height="8"
      transform="matrix(-1 0 0 1 58 8)"
      fill="#FF4345"
    />
    <rect
      width="8"
      height="8"
      transform="matrix(-1 0 0 1 58 0)"
      fill="#FF4345"
    />
    <rect
      width="26"
      height="8"
      transform="matrix(-1 0 0 1 58 64)"
      fill="#FF4345"
    />
    <rect
      width="18"
      height="8"
      transform="matrix(-1 0 0 1 58 72)"
      fill="#FF4345"
    />
    <rect
      width="8"
      height="8"
      transform="matrix(-1 0 0 1 58 80)"
      fill="#FF4345"
    />
    <rect
      width="8"
      height="8"
      transform="matrix(-1 0 0 1 8 48)"
      fill="#FF4345"
    />
    <rect
      width="8"
      height="8"
      transform="matrix(-1 0 0 1 8 32)"
      fill="#FF4345"
    />
    <rect
      width="8"
      height="48"
      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 114.799 29.6562)"
      fill="#FF4345"
    />
    <rect
      width="8"
      height="48"
      transform="matrix(-0.707107 0.707107 0.707107 0.707107 80.6572 24)"
      fill="#FF4345"
    />
  </svg>
);

export const SoundOnSVG = (
  <svg
    width="83"
    height="64"
    viewBox="0 0 83 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: "30px" }}
  >
    <rect
      width="36.087"
      height="29.0909"
      transform="matrix(-1 0 0 1 41.8613 17.4531)"
      fill="#F9D42A"
    />
    <rect
      width="18.7652"
      height="5.81818"
      transform="matrix(-1 0 0 1 41.8613 11.6367)"
      fill="#F9D42A"
    />
    <rect
      width="12.9913"
      height="5.81818"
      transform="matrix(-1 0 0 1 41.8613 5.81641)"
      fill="#F9D42A"
    />
    <rect
      width="5.77391"
      height="5.81818"
      transform="matrix(-1 0 0 1 41.8613 0)"
      fill="#F9D42A"
    />
    <rect
      width="18.7652"
      height="5.81818"
      transform="matrix(-1 0 0 1 41.8613 46.5469)"
      fill="#F9D42A"
    />
    <rect
      width="12.9913"
      height="5.81818"
      transform="matrix(-1 0 0 1 41.8613 52.3633)"
      fill="#F9D42A"
    />
    <rect
      width="5.77391"
      height="5.81818"
      transform="matrix(-1 0 0 1 41.8613 58.1836)"
      fill="#F9D42A"
    />
    <rect
      width="5.77391"
      height="5.81818"
      transform="matrix(-1 0 0 1 5.77344 34.9102)"
      fill="#F9D42A"
    />
    <rect
      width="5.77391"
      height="5.81818"
      transform="matrix(-1 0 0 1 5.77344 23.2734)"
      fill="#F9D42A"
    />
    <rect
      width="5.77391"
      height="46.5455"
      transform="matrix(-1 0 0 1 83 8.72656)"
      fill="#F9D42A"
    />
    <rect
      width="5.77392"
      height="34.9091"
      transform="matrix(-1 0 0 1 70.0088 14.5469)"
      fill="#F9D42A"
    />
    <rect
      width="5.77391"
      height="11.6364"
      transform="matrix(-1 0 0 1 57.0176 26.1836)"
      fill="#F9D42A"
    />
  </svg>
);
