import React from "react";
import "../assets/scss/components/swapEagleItem.scss";

const SwapEagleItem = ({
  eagleImage,
  eagleName,
  currency,
  issuer,
  claimId,
  handleClick,
  isLoading,
  label = "Swap",
  backgroundColor = "yellow",
  className = "",
  titleClassName = "",
}) => (
  <div
    className={`eagle-block bg-${backgroundColor} ${className}`}
    id="eagle-block"
  >
    <div className="img-eagle-wrap">
      <img src={eagleImage} className="img-fluid eagle-img" alt="eagle" />
    </div>
    <div className="content-block">
      <div className={`text-center ${titleClassName}`}>{eagleName}</div>
      <div className="ct-block-2">
        <button
          className="mt-0 nes-btn is-error"
          onClick={() =>
            claimId ? handleClick(claimId) : handleClick(currency, issuer)
          }
        >
          {isLoading ? "Loading..." : label}
        </button>
      </div>
    </div>
  </div>
);

export default SwapEagleItem;
