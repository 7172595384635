import React, { useEffect, useState } from "react";
import icon from "../assets/images/trophy-icon.png";
import "../assets/scss/pages/rewards.scss";
import { rewardsAPI } from "../common/api-endpoints";
import HTTPService from "../common/httpService";
import { Loader, RewardBlock } from "../components";
import SeoMetaTags from "../components/SeoMetaTags";

const Reward = () => {
  const [loading, setLoading] = useState(true);
  const [rewards, setRewards] = useState([]);

  useEffect(() => {
    HTTPService.get(rewardsAPI)
      .then((res) => {
        if (res.data) {
          setRewards(res.data);
        }
      })
      .catch((e) => {
        console.log("Error:", e);
      });
    setLoading(!loading);
  }, []);

  return (
    <React.Fragment>
      <SeoMetaTags title={"Rewards"} />
      {loading ? (
        <Loader />
      ) : (
        <div className="common-lap shop-main-wrapper">
          <div className="title-block">
            <div className="d-flex inner-tl">
              <div className=" mr-2">
                <img src={icon} alt="reward" className="img-fluid" />
              </div>
              <h3 className="tx-twelve">Rewards</h3>
            </div>
          </div>
          <div className="common-inner-lap">
            {rewards.map((reward) => (
              <RewardBlock key={reward._id} reward={reward} />
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Reward;
