import React from "react";
import smallGlass from "../../assets/images/small-glass.png";
// @ts-ignore
import { CheckCircle, Clock, XCircle } from "feather-icons-react";
import Modal from "react-modal";
import styles from "./SendToTimeMachineStatusPopup.module.scss";
import { XUM_TRANSACTION_STATES } from "./hook/useShopPageWebHook";
import classNames from "classnames";

const SendToTimeMachineStatusPopup = ({
  isOpen = false,
  onClose = () => {},
  onOk = () => {},
  purchaseStatus = XUM_TRANSACTION_STATES.INIT,
}) => {
  const purchaseIsSuccessfull =
    purchaseStatus === XUM_TRANSACTION_STATES.SUCCESS;
  const purchaseIsFailed = purchaseStatus === XUM_TRANSACTION_STATES.FAILED;
  const purchaseIsTimeout = purchaseStatus === XUM_TRANSACTION_STATES.TIMEOUT;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div className="d-flex">
        <h6 className="primary-font f-400">
          {purchaseIsSuccessfull
            ? "Item purchased successfully"
            : purchaseIsFailed
            ? "Failed to purchase the item"
            : purchaseIsTimeout
            ? "Purchase timeout please try again"
            : ""}
        </h6>
        <img src={smallGlass} alt="img" className="img-fluid" />
      </div>
      <div className={classNames(styles.status)}>
        {purchaseIsSuccessfull ? (
          <span className={styles.success}>
            <CheckCircle size={50} />
          </span>
        ) : purchaseIsFailed ? (
          <span className={styles.failed}>
            <XCircle size={50} />
          </span>
        ) : purchaseIsTimeout ? (
          <span>
            <Clock size={50} />
          </span>
        ) : (
          ""
        )}
      </div>
      <div className={styles.buttons}>
        <button type="button" className="nes-btn is-error" onClick={onOk}>
          ok
        </button>
      </div>
    </Modal>
  );
};

export default SendToTimeMachineStatusPopup;
