import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NEST_WRAPPERS } from "../../../../common/constants";
import { updateActiveStakeByName } from "../../../../store/activeStakeSlice";
import { NEST_NAMES, setActiveNest } from "../../../../store/nestSlice";
import {
  fetchActiveStakes,
  setCanDoNewStake,
} from "../../../../store/activeStakeSlice";

const checkIfCanPutNewNft = (stakes: any, key: string) => {
  return stakes[key]?.nfts?.length > 1 ? false : true;
};

const useInventoryEagles = () => {
  const dispatch = useDispatch();
  const [selectedNft, setSelectedNft] = useState<number[]>([]);

  const {
    nest: { activeNestWrapper, activeNest },
    stake: { activeStakesByName },
  } = useSelector((state: any) => state);

  // reset stake nft selection on nest change
  useEffect(() => {
    if (selectedNft.length) {
      resetState();
    }
  }, [activeNestWrapper]);

  const resetState = () => {
    setSelectedNft([]);
    dispatch(setCanDoNewStake(false));
    // @ts-ignore
    dispatch(fetchActiveStakes());
  };

  // TODO: do not repeat yourself
  const handleInventoryEagleClick = (nft: any) => {
    if (activeNestWrapper === NEST_WRAPPERS.DEFAULT) {
      dispatch(
        updateActiveStakeByName({
          wrapper: NEST_WRAPPERS.DEFAULT,
          nest: "s",
          nfts: [...selectedNft, nft],
        })
      );
      dispatch(setActiveNest(NEST_NAMES.DEFAULT.s));
      setSelectedNft((prev) => [...prev, nft]);
    } else if (activeNestWrapper === NEST_WRAPPERS.DOUBLE_STORY) {
      if (
        checkIfCanPutNewNft(
          activeStakesByName[NEST_WRAPPERS.DOUBLE_STORY],
          "d1"
        )
      ) {
        dispatch(
          updateActiveStakeByName({
            wrapper: NEST_WRAPPERS.DOUBLE_STORY,
            nest: "d1",
            nfts: [...selectedNft, nft],
          })
        );
        dispatch(setActiveNest(NEST_NAMES.DOUBLE_STORY.d1));
        setSelectedNft((prev) => [...prev, nft]);
      } else if (
        checkIfCanPutNewNft(
          activeStakesByName[NEST_WRAPPERS.DOUBLE_STORY],
          "d2"
        )
      ) {
        dispatch(
          updateActiveStakeByName({
            wrapper: NEST_WRAPPERS.DOUBLE_STORY,
            nest: "d2",
            nfts: [...selectedNft, nft],
          })
        );
        dispatch(setActiveNest(NEST_NAMES.DOUBLE_STORY.d2));
        setSelectedNft((prev) => [...prev, nft]);
      }
    } else if (activeNestWrapper === NEST_WRAPPERS.PIRATE_SHIP) {
      if (
        checkIfCanPutNewNft(activeStakesByName[NEST_WRAPPERS.PIRATE_SHIP], "t1")
      ) {
        dispatch(
          updateActiveStakeByName({
            wrapper: NEST_WRAPPERS.PIRATE_SHIP,
            nest: "t1",
            nfts: [...selectedNft, nft],
          })
        );
        dispatch(setActiveNest(NEST_NAMES.PIRATE_SHIP.t1));
        setSelectedNft((prev) => [...prev, nft]);
      } else if (
        checkIfCanPutNewNft(activeStakesByName[NEST_WRAPPERS.PIRATE_SHIP], "t2")
      ) {
        dispatch(
          updateActiveStakeByName({
            wrapper: NEST_WRAPPERS.PIRATE_SHIP,
            nest: "t2",
            nfts: [...selectedNft, nft],
          })
        );
        dispatch(setActiveNest(NEST_NAMES.PIRATE_SHIP.t2));
        setSelectedNft((prev) => [...prev, nft]);
      } else if (
        checkIfCanPutNewNft(activeStakesByName[NEST_WRAPPERS.PIRATE_SHIP], "t3")
      ) {
        dispatch(
          updateActiveStakeByName({
            wrapper: NEST_WRAPPERS.PIRATE_SHIP,
            nest: "t3",
            nfts: [...selectedNft, nft],
          })
        );
        dispatch(setActiveNest(NEST_NAMES.PIRATE_SHIP.t3));
        setSelectedNft((prev) => [...prev, nft]);
      }
    } else if (activeNestWrapper === NEST_WRAPPERS.CASTLE) {
      if (checkIfCanPutNewNft(activeStakesByName[NEST_WRAPPERS.CASTLE], "q1")) {
        dispatch(
          updateActiveStakeByName({
            wrapper: NEST_WRAPPERS.CASTLE,
            nest: "q1",
            nfts: [...selectedNft, nft],
          })
        );
        dispatch(setActiveNest(NEST_NAMES.CASTLE.q1));
        setSelectedNft((prev) => [...prev, nft]);
      } else if (
        checkIfCanPutNewNft(activeStakesByName[NEST_WRAPPERS.CASTLE], "q2")
      ) {
        dispatch(
          updateActiveStakeByName({
            wrapper: NEST_WRAPPERS.CASTLE,
            nest: "q2",
            nfts: [...selectedNft, nft],
          })
        );
        dispatch(setActiveNest(NEST_NAMES.CASTLE.q2));
        setSelectedNft((prev) => [...prev, nft]);
      } else if (
        checkIfCanPutNewNft(activeStakesByName[NEST_WRAPPERS.CASTLE], "q3")
      ) {
        dispatch(
          updateActiveStakeByName({
            wrapper: NEST_WRAPPERS.CASTLE,
            nest: "q3",
            nfts: [...selectedNft, nft],
          })
        );
        dispatch(setActiveNest(NEST_NAMES.CASTLE.q3));
        setSelectedNft((prev) => [...prev, nft]);
      } else if (
        checkIfCanPutNewNft(activeStakesByName[NEST_WRAPPERS.CASTLE], "q4")
      ) {
        dispatch(
          updateActiveStakeByName({
            wrapper: NEST_WRAPPERS.CASTLE,
            nest: "q4",
            nfts: [...selectedNft, nft],
          })
        );
        dispatch(setActiveNest(NEST_NAMES.CASTLE.q4));
        setSelectedNft((prev) => [...prev, nft]);
      }
    }
  };

  const removeEagleFromSelection = (eagleNft: any) => {
    const filteredEaglesNft = [...selectedNft].filter(
      (nft: any) => nft.stakeValue !== eagleNft.stakeValue
    );
    dispatch(
      updateActiveStakeByName({
        wrapper: activeNestWrapper,
        nest: activeNest,
        nfts: filteredEaglesNft,
      })
    );
    setSelectedNft(filteredEaglesNft);
  };

  return {
    handleInventoryEagleClick,
    selectedNft,
    setSelectedNft,
    removeEagleFromSelection,
  };
};

export default useInventoryEagles;
