import toast from "react-hot-toast";
import user from "../assets/images/user.png";
import "../assets/scss/components/user.scss";

const User = ({ walletId = "Wallet ID", profilePic = "" }) => {
  return (
    <div className="userwrapper">
      <div className="img-wrap">
        <div className="countrywrapper">
          {/* <img src={country} className="img-fluid" alt="country" /> */}
        </div>
        <div className="user-img-block">
          <img src={profilePic || user} className="img-fluid" alt="user" />
        </div>
        <div className="text-block">
          <h5 className="tx-sixth mb-0">Pro</h5>
        </div>
      </div>
      <div className="id-text-block">
        <h5
          className="tx-sixth mb-0 truncate-wallet"
          title={walletId}
          onClick={(e) => {
            navigator.clipboard
              .writeText(e.target.innerText)
              .then(() => {
                toast.success("Wallet address copied to clipboard");
              })
              .catch((e) => {
                toast.error(
                  `Failed to copy wallet address to clipboard, please try again ${e?.message}`
                );
              });
          }}
        >
          {walletId}
        </h5>
      </div>
    </div>
  );
};

export default User;
