import "../assets/scss/components/creditsBlock.scss";

const CreditsBLock = ({ credit = {} }) => {
  return (
    <div className="main-block credits">
      <div className="profile-image">
        <img
          src={credit.profilePic}
          alt="profile avatar"
          className="img-fluid kg-1"
        />
      </div>
      <div className="details">
        <p className="name">{credit.name}</p>
        <p className="desc">{credit.description}</p>
      </div>
      <div className="logos">
        {credit.twitter && (
          <a href={credit.twitter} target="_blank" rel="noreferrer">
            <i className="nes-icon twitter is-medium"></i>
          </a>
        )}
        {credit.web && (
          <a href={credit.web} target="_blank" rel="noreferrer">
            <img
              src={credit.webIcon}
              alt="web icon"
              style={{ maxWidth: 48, marginBottom: 3, borderRadius: 10 }}
            />
          </a>
        )}
      </div>
    </div>
  );
};

export default CreditsBLock;
