import "../assets/scss/components/modal.scss";
import smallGlass from "../assets/images/small-glass.png";

const AcceptanceModal = ({ resume }) => {
  return (
    <div className="modal-backdrop">
      <div className="modal-content stack-contain">
        <div className="blck">
          <div className="innerblock">
            <div className="main-wrap">
              <div className="wrp">
                <div className="first-block">
                  <h6 className="primary-font f-400">"User,</h6>
                </div>
                <div className="second-block">
                  <img src={smallGlass} alt="img" className="img-fluid" />
                </div>
              </div>
              {resume ? (
                <h6 className="primary-font f-400">
                  You have begun staking <br /> your SuperEagle NFT"
                </h6>
              ) : (
                <h6 className="primary-font f-400">
                  Staking has been stopped"
                </h6>
              )}
              <div className="btn-wrapper mt-2">
                <button
                  type="button"
                  className="custom mt-0 nes-btn is-error"
                  onClick={() => window.location.reload()}
                >
                  ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptanceModal;
