import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPlayers } from "../../store/allPlayersSlice";
import { allPlayersSelectors } from "../../store/selectors";

const AllPlayersPagination = () => {
  const dispatch = useDispatch();

  const { community, loading } = useSelector(allPlayersSelectors);
  const [activePage, setActivePage] = useState(0);

  const fetchPaginatedAllPlayers = (page = 0) => {
    dispatch(fetchAllPlayers({ page }));
    setActivePage(page);
  };

  useEffect(() => {
    fetchPaginatedAllPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, community]);

  return (
    <div className="pagination d-flex justify-content-center gap-2 mt-3">
      {[0, 1].map((page) => (
        <button
          className={`nes-btn ${
            loading || activePage === page ? "is-disabled" : ""
          }`}
          onClick={() => {
            fetchPaginatedAllPlayers(page);
          }}
        >
          {page + 1}
        </button>
      ))}
    </div>
  );
};

export default AllPlayersPagination;
