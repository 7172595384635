import React from "react";
import Modal from "react-modal";
import styles from "./UseInventoryPotionModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedInventoryItem,
  setUsePotionModalVisibility,
  useInventoryPotionOnSelectedNest,
} from "../../store/inventoryPotionsSlice";
import { toast } from "react-hot-toast";
import classNames from "classnames";

const UseInventoryPotionModal = () => {
  const dispatch = useDispatch();

  const {
    selected: selectedInventoryItem,
    usePotionModalVisible,
    useInventoryItemLoading,
  } = useSelector((state: any) => state.inventory);
  const {
    nest: { activeNestWrapper, activeNest },
    stake: { activeStakesByName },
  } = useSelector((state: any) => state);

  const nfts = activeStakesByName[activeNestWrapper][activeNest]?.nfts;
  const stakeId = activeStakesByName[activeNestWrapper][activeNest]?.id;

  const useSelectedInventoryItemOnSelectedNest = () => {
    const inventoryId = selectedInventoryItem.id;
    // TODO: update async thunk dispatch type
    // @ts-ignore
    dispatch(useInventoryPotionOnSelectedNest({ inventoryId, stakeId }));
  };

  if (!activeNest || !nfts?.length) {
    toast.error("No nfts or nest is selected.");
    return null;
  }

  return (
    <Modal
      isOpen={usePotionModalVisible}
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div className="d-flex">
        <h6 className="primary-font f-400">
          You are about to use {selectedInventoryItem.item?.name} on
        </h6>
        <img
          src={selectedInventoryItem.item?.image}
          alt="img"
          className={classNames("img-fluid", styles.selectedItemImage)}
        />
      </div>
      <div className={styles.info}>
        <p>
          <span>Nest:</span> {activeNest?.toUpperCase()}
        </p>
        <p>
          <span>Nfts:</span> {nfts[0]?.name} <span>and </span>
          {nfts[1]?.name}
        </p>
      </div>
      <div className={styles.buttons}>
        <button
          type="button"
          className="nes-btn is-error"
          onClick={useSelectedInventoryItemOnSelectedNest}
          disabled={useInventoryItemLoading}
        >
          {useInventoryItemLoading ? "USING..." : "OK"}
        </button>
        <button
          type="button"
          className="nes-btn is-error"
          onClick={() => {
            dispatch(setSelectedInventoryItem({}));
            dispatch(setUsePotionModalVisibility(false));
          }}
          disabled={useInventoryItemLoading}
        >
          CANCEL
        </button>
      </div>
    </Modal>
  );
};

export default UseInventoryPotionModal;
