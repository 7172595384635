import { useEffect, useState } from "react";

export const XUM_TRANSACTION_STATES = {
  FAILED: "failed",
  SUCCESS: "success",
  PENDING: "pending",
  TIMEOUT: "timeout",
  INIT: "init",
};

const useShopPageWebHook = () => {
  const [transactionId, setTransactionId] = useState("");
  const [socketUrl, setSocketUrl] = useState("");
  const [transactionStatus, setTransactionStatus] = useState(
    XUM_TRANSACTION_STATES.INIT
  );

  const listenToPurchaseSocket = (webhookUrl: string) => {
    setSocketUrl(webhookUrl);
  };

  const closeSocket = () => {
    setSocketUrl("");
    setTransactionId("");
    setTransactionStatus(XUM_TRANSACTION_STATES.INIT);
  };

  useEffect(() => {
    let webSocket: WebSocket;
    let socketTerminationTimeout: ReturnType<typeof setTimeout>;

    if (socketUrl) {
      webSocket = new WebSocket(socketUrl);

      webSocket.onopen = () => {
        // close socket after 5 minutes if user inactive for 5 minutes
        socketTerminationTimeout = setTimeout(() => {
          webSocket.close();
          setTransactionStatus(XUM_TRANSACTION_STATES.TIMEOUT);
        }, 300000);
      };

      webSocket.onmessage = (response) => {
        const transactionData = JSON.parse(response.data);

        if (transactionData.signed) {
          // handle success transaction
          setTransactionId(transactionData.txid);
          setTransactionStatus(XUM_TRANSACTION_STATES.SUCCESS);
          webSocket.close();
        } else if (transactionData.signed === false) {
          setTransactionStatus(XUM_TRANSACTION_STATES.FAILED);
          webSocket.close();
        } else {
          // handle in processing
        }
      };
    }

    return () => {
      webSocket?.close();
      clearTimeout(socketTerminationTimeout);
    };
  }, [socketUrl]);

  return {
    transactionId,
    transactionStatus,
    listenToPurchaseSocket,
    closeSocket,
  };
};

export default useShopPageWebHook;
