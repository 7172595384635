import { NavLink } from "react-router-dom";
import goggles from "../assets/images/goggles.png";
import "../assets/scss/components/sidebar.scss";

const Sidebar = () => {
  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-link-list">
        <NavLink to="/home" active="true">
          <div className="page-sidebar-link">
            <div className="active-icon">
              <img src={goggles} className="img-fluid" alt="goggles" />
            </div>
            <div className="page-link-content">My NFTs</div>
          </div>
        </NavLink>
        <NavLink to="/swap">
          <div className="page-sidebar-link">
            <div className="active-icon">
              <img src={goggles} className="img-fluid" alt="goggles" />
            </div>
            <div className="page-link-content">Swap</div>
          </div>
        </NavLink>
        <NavLink to="/withdraw">
          <div className="page-sidebar-link">
            <div className="active-icon">
              <img src={goggles} className="img-fluid" alt="goggles" />
            </div>
            <div className="page-link-content">Withdraw</div>
          </div>
        </NavLink>
        <NavLink to="/shop">
          <div className="page-sidebar-link">
            <div className="active-icon">
              <img src={goggles} className="img-fluid" alt="goggles" />
            </div>
            <div className="page-link-content">Shop</div>
          </div>
        </NavLink>
        <NavLink to="/time-machine">
          <div className="page-sidebar-link">
            <div className="active-icon">
              <img src={goggles} className="img-fluid" alt="goggles" />
            </div>
            <div className="page-link-content">Time Machine</div>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
