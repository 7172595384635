export const loginAPI = "/auth/login";

export const loginWithXUMMAPI = "/auth/sign-in-xumm";

export const verifyXUMMAPI = "/auth/get-access";

export const nestAPI = "/nest";

export const stakeAPI = "/stake";

export const allPlayersAPI = "/stake/all-players/";

export const communityAPI = "/stake/my-community";

export const addCommunityAPI = "/stake/add-community";

export const removeCommunityAPI = "/stake/remove-community";

export const stopStakeAPI = "/stake/stop";

export const activeStakeAPI = "/stake/active";

export const accountNFTAPI = "/nft/my-nfts";

export const accountInventoryAPI = "/inventory";

export const accountUseInventoryItemAPI = "/inventory/consume";

export const transactionListAPI = "/transactions/user-transactions";

export const myNftCountAPI = "/nft/my-nft-count";

export const reportBugAPI = "/bug-report";

export const getNotificationsAPI = "/notifications/all";

export const clearNotificationAPI = "notifications/clear";

export const markReadNotificationAPI = "/notifications/mark-read";

export const itemsAPI = "/shop-item";

export const babyNfts = "/nft/my-baby-nfts";

export const sendToTimeMachine = "/threed-nft/request";

export const rewardsAPI = "/rewards/user";

export const rewardsMint = "/rewards/mint-reward";

export const buyNFT = "/nft/buy-game-nft/";

export const buyPotion = "/cart/singleCheckOut/";

export const swapNFTAPI = "/nft/get-nfts-for-swap";

export const listClaimNFTAPI = "/nft/list-nfts-to-claim";

export const swapRequestNFTAPI = "/nft/swap-request";

export const claimRequestNFTAPI = "/nft/request-to-claim";
