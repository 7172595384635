import { useSelector } from "react-redux";
import { useTimer } from "react-timer-hook";
import hourGlass from "../assets/images/timer.png";

interface TimerProps {
  index: number;
  total: number;
  expiryTimestamp: Date;
  nestPosition: string;
}

const Timer: React.FC<TimerProps> = ({
  index = 0,
  total = 0,
  expiryTimestamp = new Date(),
  nestPosition = "",
}) => {
  const { minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return (
    <div className="counter-content">
      <div className="title-wrapper">
        <div className="first-block">
          <h3 className="primary-font">
            Stake <span className="tx-tenth">{nestPosition || index}</span>
          </h3>
        </div>
        <div className="second-block">
          <h4 className="tx-tenth primary-font">
            {index} / {total}
          </h4>
        </div>
      </div>
      <div className="counter-imer-block">
        <div className="i1-block">
          <img src={hourGlass} className="img-fluid counter-bell" alt="timer" />
        </div>
        <div className="counter-time">
          <ul>
            <li>
              <div className="t-title">{days}</div>
              <div className="day-tl">Days</div>
            </li>
            <li>
              <div className="t-title tk">:</div>
            </li>
            <li>
              <div className="t-title">{hours}</div>
              <div className="day-tl">Hours</div>
            </li>
            <li>
              <div className="t-title tk">:</div>
            </li>
            <li>
              <div className="t-title">{minutes}</div>
              <div className="day-tl">Mins</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Timer;
