export const { REACT_APP_API_URL } = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
};

export const localStorageKeys = {
  isLoggedIn: "isLoggedIn",
  token: "token",
  user: "user",
};

export const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
export const fileFormats = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "video/mp4",
];

export const xrpAddress = "rBendrS3ekrYkC4LFvamoRknYhZfZqb3aC";
export const password = "secgame@x2022%";

export const colors = ["yellow", "light-blue", "green", "pink"];

export const totalEagles = 900;

export const NEST_WRAPPERS = {
  DEFAULT: "default",
  DOUBLE_STORY: "doubleStoryBirdBox",
  PIRATE_SHIP: "tripleNestPirateShip",
  CASTLE: "castleQuadNest",
};
