import { useEffect, useState } from "react";
import icon from "../../assets/images/trophy-icon.png";
import {
  claimRequestNFTAPI,
  listClaimNFTAPI,
  myNftCountAPI,
} from "../../common/api-endpoints";
import { colors } from "../../common/constants";
import HTTPService from "../../common/httpService";
import SeoMetaTags from "../../components/SeoMetaTags";
import SwapEagleItem from "../../components/swapEagleItem";
import WithdrawOpenInXumModal from "../../components/OpenInXumOrNewWindowModal";
import "../../assets/scss/pages/withdraw.scss";
import useShopPageWebHook, {
  XUM_TRANSACTION_STATES,
} from "../shop/hook/useShopPageWebHook";
import ShopPurchaseStatusPopup from "../shop/ShopPurchaseStatusPopup";

const item = {
  usageCount: 1,
  name: "Random Eagle",
  description:
    "Buy random eagle now and you can use this eagle in game once XLS-20 launches",
  secPrice: "100",
  image: "https://supereaglebucket.s3.amazonaws.com/MysteryEagle.png",
  currency: "XRP",
  extra: {
    sold: "174",
    total: "1000",
  },
  id: "6340fa68e45b5dc6bcfa6582",
};
const Withdraw = () => {
  const {
    listenToPurchaseSocket,
    transactionId,
    transactionStatus,
    closeSocket,
  } = useShopPageWebHook();

  const [nftCount, setNftCount] = useState(0);
  const [nftsToClaim, setNftsToClaim] = useState([]);
  const [txStatusModal, setTxStatusModal] = useState(false);

  useEffect(() => {
    if (transactionStatus !== XUM_TRANSACTION_STATES.INIT) {
      setTxStatusModal(true);
      setOpenXumModal(false);
    } else {
      setTxStatusModal(false);
    }
  }, [transactionStatus]);

  useEffect(() => {
    HTTPService.get(myNftCountAPI).then((response) => {
      setNftCount(response.data.nftsOwned);
    });
    HTTPService.get(listClaimNFTAPI).then((response) => {
      setNftsToClaim(response.data.nftsToClaim);
    });
  }, []);

  const [openXumModal, setOpenXumModal] = useState(false);
  const [xummModalContent, setXummModalContent] = useState({});
  const [claimLoading, setClaimLoading] = useState([]);

  const closeXumModalAndCloseSocket = () => {
    closeSocket();
    setOpenXumModal(false);
    setTxStatusModal(false);
  };

  const handleClaim = (claimId) => {
    if (!claimId) return null;
    let indexNumber = nftsToClaim.findIndex(({ nft }) => nft.id === claimId);
    setClaimLoading((prev) => {
      let loadingState = [...prev];
      loadingState[indexNumber] = true;

      return loadingState;
    });
    HTTPService.post(claimRequestNFTAPI, { claimId })
      .then((response) => {
        setOpenXumModal(true);
        setXummModalContent({
          xummLink: response.data.nftsToClaim.next.always,
          qrCodeImage: response.data.nftsToClaim.refs.qr_png,
        });
        setClaimLoading((prev) => {
          let loadingState = [...prev];
          loadingState[indexNumber] = false;

          return loadingState;
        });
        listenToPurchaseSocket(response.data.nftsToClaim.refs.websocket_status);
      })
      .catch((err) => {
        setClaimLoading((prev) => {
          let loadingState = [...prev];
          loadingState[indexNumber] = false;

          return loadingState;
        });
      });
  };

  return (
    <div className="common-lap shop-main-wrapper">
      <SeoMetaTags title={"Withdraw"} />
      <div className="title-block">
        <div className="d-flex inner-tl">
          <div className=" mr-2">
            <img src={icon} alt="reward" className="img-fluid" />
          </div>
          <h3 className="tx-twelve">Withdraw</h3>
        </div>
      </div>
      <div className="common-inner-lap eagle-container">
        <div
          className={`eagle-block bg-${
            colors[Math.floor(Math.random() * colors.length)]
          }`}
        >
          <h6 className="tx-sixth shop-nft-title text-center  f-700">
            {nftCount > 0 ? `NFTs Bought: ${nftCount}` : `Qty: 0`}
          </h6>
          <div className="img-eagle-wrap">
            <img src={item.image} className="img-fluid eagle-img" alt="eagle" />
          </div>
          <div className="ct-block-2">
            <h4 className="tx-sixth e-title">{item.name} </h4>
            <h6 className="tx-sixth e-sub-title">{item.description}</h6>
          </div>
        </div>

        {nftsToClaim.map(({ nft, meta }, index) => {
          if (nft.isClaimed) return null;
          return (
            <SwapEagleItem
              eagleImage={meta.image}
              claimId={nft.id}
              eagleName={meta.name}
              handleClick={handleClaim}
              isLoading={claimLoading[index]}
              key={nft.id}
              label="Claim"
              backgroundColor={
                colors[Math.floor(Math.random() * colors.length)]
              }
              titleClassName="h4 f-700"
            />
          );
        })}
      </div>
      <WithdrawOpenInXumModal
        xummLink={xummModalContent.xummLink}
        qrCodeImage={xummModalContent.qrCodeImage}
        closeModal={closeXumModalAndCloseSocket}
        modalIsOpen={openXumModal}
      />
      <ShopPurchaseStatusPopup
        isOpen={txStatusModal}
        purchaseStatus={transactionStatus}
        onOk={() => {
          setTxStatusModal(false);
        }}
      />
    </div>
  );
};

export default Withdraw;
