import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChevronLeft, ChevronRight } from "feather-icons-react";
import { fetchActiveStakes } from "../store/activeStakeSlice";
import { timeBoardSelector } from "./selector";
import Timer from "./Timer";
import "../assets/scss/components/timeboard.scss";
import board from "../assets/images/counter.jpeg";
import { NEST_NAMES } from "../store/nestSlice";

// put nest names at their indexes
// ex [s, d1, d2, t1 .....]
const nestPositions = Object.values(NEST_NAMES)
  .map((item) => Object.keys(item))
  .flat();

const formatTime = (duration) => {
  var days = Math.floor(duration / (3600 * 24));
  var hours = Math.floor((duration % (3600 * 24)) / 3600);
  var minutes = Math.floor((duration % 3600) / 60);

  days = days < 10 ? "0" + days : days;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return { days, hours, minutes };
};

const TimeBoard = () => {
  const dispatch = useDispatch();

  const { activeNest, nests } = useSelector((state) => state.nest);

  const stakingTimes = useSelector(timeBoardSelector);

  // refetch active stake api every 30 minutes
  useEffect(() => {
    const refreshActiveStakes = setInterval(() => {
      dispatch(fetchActiveStakes());
    }, 1800000);

    return () => {
      clearInterval(refreshActiveStakes);
    };
  }, [dispatch]);

  const stakeTimersList = useMemo(() => {
    return stakingTimes.map((stake, i) => {
      const { days, hours, minutes } = formatTime(stake.remainSeconds);
      return (
        <Timer
          nestPosition={stake.nestPosition}
          key={i}
          index={i + 1}
          total={stakingTimes.length}
          days={days}
          hours={hours}
          minutes={minutes}
          expiryTimestamp={new Date(stake.endTime)}
        />
      );
    });
  }, [stakingTimes]);

  const [activeTimer, setActiveTimer] = useState(0);

  // show timer for active stake board
  useEffect(() => {
    if (activeNest) {
      // only find index from the nests which are currently in stake then set timeboard
      // we do not want to set index to t3(6) and we have an empty nest in between
      let activeNestIndex = nestPositions
        ?.filter((nestName) => {
          return nests.find((nest) => nest.position?.toLowerCase() === nestName)
            ?.nfts?.length;
        })
        .findIndex((nest) => nest === activeNest);

      setActiveTimer(activeNestIndex >= 0 ? activeNestIndex : 0);
    } else {
      setActiveTimer(0);
    }
  }, [activeNest]);

  return (
    <div className="counter-board">
      <ChevronLeft
        className="left"
        onClick={() => {
          activeTimer > 0 && setActiveTimer((prev) => prev - 1);
        }}
      />
      <img src={board} className="img-fluid counter-img" alt="board" />
      {stakingTimes.length > 0 ? stakeTimersList[activeTimer] : <Timer />}
      <ChevronRight
        className="right"
        onClick={() => {
          activeTimer < stakeTimersList.length - 1 &&
            setActiveTimer((prev) => prev + 1);
        }}
      />
    </div>
  );
};

export default TimeBoard;
