import { createSelector } from "@reduxjs/toolkit";
import { NEST_NAMES } from "../store/nestSlice";

const activeStakeSelector = (state) => state.stake.activeStakes;
const nestsSelector = (state) => state.nest.nests;

// generates - s, d1, d2, t1, t2 ......
const nestPositions = Object.values(NEST_NAMES)
  .map((wrapper) => Object.values(wrapper))
  .flat();
export const timeBoardSelector = createSelector(
  [activeStakeSelector, nestsSelector],
  (activeStakes, nests) => {
    const stakeTimers = activeStakes
      .filter(({ staking }) => staking === "running")
      .map(({ startTime, endTime, duration, remainSeconds, nest }) => ({
        duration,
        startTime,
        endTime,
        remainSeconds,
        nestPosition: nests.find((cNest) => cNest.id === nest)?.position,
      }));
    const stakeTimersSortedByNestOrder = stakeTimers.sort(
      (a, b) =>
        nestPositions.indexOf(a.nestPosition?.toLowerCase()) -
        nestPositions.indexOf(b.nestPosition?.toLowerCase())
    );

    return stakeTimersSortedByNestOrder;
  }
);

const activeStakeByNameSelector = (state) => {
  const nestWrapperName = state.nest.activeNestWrapper;
  return state.stake.activeStakesByName?.[nestWrapperName];
};

export const activeStakeBirdsSelector = createSelector(
  [activeStakeByNameSelector],
  (data) => {
    return data || {};
  }
);
