import React from "react";
import { useSelector } from "react-redux";
import {
  otherTransactionsSortedInDescOrderByDateSelector,
  stakeRewardTransactionsSortedInDescOrderByDateSelector,
} from "../../store/selectors";
import { FILTER_TYPES } from "./Transactions";
import styles from "./FilterTransactions.module.scss";

const FilterTransactions = ({ setFilterType, filterType }) => {
  const stakeRewardTransactions = useSelector(
    stakeRewardTransactionsSortedInDescOrderByDateSelector
  );
  const otherTransactions = useSelector(
    otherTransactionsSortedInDescOrderByDateSelector
  );

  const isOtherRadioDisabled = !Boolean(otherTransactions.length);
  const isStakeRadioDisabled = !Boolean(stakeRewardTransactions.length);

  return (
    <div
      className={styles.filterTransaction}
      onChange={(e) => {
        setFilterType(e.target.value);
      }}
    >
      <label>
        <input
          type="radio"
          className="nes-radio"
          name="filter"
          value={FILTER_TYPES.TRANSACTION}
          disabled={isOtherRadioDisabled}
          checked={filterType === FILTER_TYPES.TRANSACTION}
        />
        <span>Other</span>
      </label>

      <label>
        <input
          type="radio"
          className="nes-radio"
          name="filter"
          value={FILTER_TYPES.REWARD}
          disabled={isStakeRadioDisabled}
          checked={filterType === FILTER_TYPES.REWARD}
        />
        <span>Rewards</span>
      </label>
    </div>
  );
};

export default FilterTransactions;
