import React from "react";
import { useSelector } from "react-redux";

const NestsList = () => {
  const inventory = useSelector((state: any) => state.nft.inventory);
  return (
    <ul className="nft-ul">
      {inventory.map(
        ({ name, NFTokenID, pixel_image }: { [x: string]: string }) => {
          return (
            <div key={NFTokenID}>
              <li className={`tooltip `}>
                <img src={pixel_image} alt={name} className="img-fluid " />
                <span className="tooltiptext">{name}</span>
              </li>
            </div>
          );
        }
      )}
    </ul>
  );
};

export default NestsList;
