import { Navigate, useRoutes } from "react-router-dom";
import { Sidebar } from "./components";
import Layout from "./layouts";
import { History, Home, Login, Reward, Shop, Start } from "./pages";
import Credits from "./pages/Credits";
import Notifications from "./pages/Notifications";
import Swap from "./pages/swap";
import Withdraw from "./pages/withdraw/Withdraw";
import TimeMachinePage from "./pages/timeMachine/TimeMachine";

export default function Router({ isLoggedIn }) {
  return useRoutes([
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "credits",
      element: isLoggedIn ? (
        <Layout children={<Credits />} />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "notifications",
      element: isLoggedIn ? (
        <Layout children={<Notifications />} />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "history",
      element: isLoggedIn ? (
        <Layout children={<History />} />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "home",
      element: isLoggedIn ? (
        <Layout children={<Home />} sidebar={<Sidebar />} />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "rewards",
      element: isLoggedIn ? (
        <Layout children={<Reward />} sidebar={<Sidebar />} />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "withdraw",
      element: isLoggedIn ? (
        <Layout children={<Withdraw />} sidebar={<Sidebar />} />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "shop",
      element: isLoggedIn ? (
        <Layout children={<Shop />} sidebar={<Sidebar />} />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "time-machine",
      element: isLoggedIn ? (
        <Layout children={<TimeMachinePage />} sidebar={<Sidebar />} />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "swap",
      element: isLoggedIn ? (
        <Layout children={<Swap />} sidebar={<Sidebar />} />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "start",
      element: isLoggedIn ? (
        <Layout children={<Start />} />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/",
      element: isLoggedIn ? <Navigate to="home" /> : <Navigate to="login" />,
    },
  ]);
}
