import React from "react";
import { useSelector } from "react-redux";
import Trophy from "../../components/Trophy";
import User from "../../components/User";
import { allPlayersSelectors } from "../../store/selectors";
import emptyHeart from "../../assets/images/empty-heart.png";
import filledHeart from "../../assets/images/fill-heart.png";
import styles from "./PlayerItem.module.scss";

const PlayerItem = ({
  player,
  addFavourite,
  removeFavourite,
  index,
  favourite,
}) => {
  const { community } = useSelector(allPlayersSelectors);

  const friend = community.find(
    (commUser) => commUser.communityStake?.id === player.staking.id
  );

  return (
    <div className={styles.communityBlock}>
      <div className={styles.heartBlock}>
        <img
          src={friend || favourite[index] ? filledHeart : emptyHeart}
          className="img-fluid"
          alt="empty heart"
          onClick={() =>
            friend || favourite[index]
              ? removeFavourite(index, player.staking.id)
              : addFavourite(index, player.staking.id)
          }
        />
      </div>
      <User
        walletId={player.staking.user?.xrpAddress}
        profilePic={player.staking.user?.profilePic}
      />
      <div className={styles.tokenBlock}></div>
      <div className="duration-wrapper-block">
        <h6 className="tx-sixth f-400">Duration</h6>
        <div className="duration-block">
          <h2 className="mb-0">
            {player.staking.duration}
            <span>:</span>00<span>:</span>00
          </h2>
        </div>
        <div className="duration-block-timer">
          <h5>Days</h5>
          <h5>Hours</h5>
          <h5>Mins</h5>
        </div>
      </div>
      <div className={styles.firstBlockWrapper}>
        <div className={styles.firstTextBlock}>
          <div className={styles.birdWrapperBlock}>
            <img
              src={player.staking.images[0]}
              className="img-fluid kg-1"
              alt="eagle"
            />
            <img
              src={player.staking.images[1]}
              className="img-fluid kg-2"
              alt="eagle"
            />
          </div>
        </div>
      </div>
      <Trophy imgUrl={player.imgUrl} />
    </div>
  );
};

export default PlayerItem;
