import React from "react";
import smallGlass from "../../assets/images/small-glass.png";
import Modal from "react-modal";
import styles from "./StakeInfoPopup.module.scss";
import { useSelector } from "react-redux";

const StakeInfoPopup = ({
  isOpen = false,
  onClose = () => {},
  onOk = () => {},
  isStaking = false,
}) => {
  const {
    nest: { activeNestWrapper, activeNest },
    stake: { activeStakesByName },
  } = useSelector((state: any) => state);

  const nfts = activeStakesByName[activeNestWrapper][activeNest]?.nfts;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div className="d-flex">
        <h6 className="primary-font f-400">
          You are about to {isStaking ? "start" : "stop"} the staking for:{" "}
        </h6>
        <img src={smallGlass} alt="img" className="img-fluid" />
      </div>
      <div className={styles.info}>
        <p>
          <span>Nest:</span> {activeNest?.toUpperCase()}
        </p>
        <p>
          <span>Nfts:</span> {nfts[0]?.name} <span>and </span>
          {nfts[1]?.name}
        </p>
      </div>
      <div className={styles.buttons}>
        <button type="button" className="nes-btn is-error" onClick={onOk}>
          OK
        </button>
        <button type="button" className="nes-btn is-error" onClick={onClose}>
          CANCEL
        </button>
      </div>
    </Modal>
  );
};

export default StakeInfoPopup;
