import { useDispatch, useSelector } from "react-redux";
import leftArrow from "../../assets/images/left-arrow.png";
import rightArrow from "../../assets/images/right-arrow.png";
import { NEST_WRAPPERS } from "../../common/constants";
import { setActiveNestWrapper } from "../../store/nestSlice";

const TOTALNESTS = 4;

const NestPagination = () => {
  const dispatch = useDispatch();
  const activeNestWrapper = useSelector(
    (state) => state.nest.activeNestWrapper
  );

  return (
    <div className="arrow-block">
      <div>
        <img
          src={leftArrow}
          className="img-fluid cursor-pointer"
          alt="left"
          onClick={() => {
            if (Object.values(NEST_WRAPPERS).indexOf(activeNestWrapper) > 0) {
              dispatch(
                setActiveNestWrapper(
                  Object.values(NEST_WRAPPERS).indexOf(activeNestWrapper) - 1
                )
              );
            }
          }}
        />
      </div>
      <h2 className="tx-eleven">
        {" "}
        {Object.values(NEST_WRAPPERS).indexOf(activeNestWrapper) + 1} /{" "}
        {TOTALNESTS}
      </h2>
      <div>
        <img
          src={rightArrow}
          className="img-fluid cursor-pointer"
          alt="right"
          onClick={() => {
            if (Object.values(NEST_WRAPPERS).indexOf(activeNestWrapper) < 3) {
              dispatch(
                setActiveNestWrapper(
                  Object.values(NEST_WRAPPERS).indexOf(activeNestWrapper) + 1
                )
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default NestPagination;
