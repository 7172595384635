import "../assets/scss/components/modal.scss";
import glasses from "../assets/images/glasses.png";

const ConfirmModal = ({
  handleOnSubmit,
  handleConfirmModal,
  resume,
  handleResumeStake,
}) => {
  const handleOnReject = () => {
    handleResumeStake(null);
    handleConfirmModal();
  };
  return (
    <>
      <div className="modal-backdrop"></div>
      <div className="modal-content normal-modal-content">
        <div className="main-wrap">
          <img src={glasses} className="img-fluid w-100" alt="" />
          <div className="left-wrapper">
            <p className="primary-font f-700 mb-1">
              {resume ? "Stake?" : "Stop staking?"}
            </p>
            <p className="primary-font f-bold small">
              Do you want to <br />{" "}
              {resume ? "stake your eagles?" : "stop current staking?"}
            </p>
          </div>
          <div className="right-wrapper">
            <div className="innerbox">
              <button
                type="button"
                className=" mt-0 nes-btn is-error"
                onClick={handleOnSubmit}
              >
                Yes
              </button>
              <button
                type="button"
                className=" mt-0 nes-btn "
                onClick={handleOnReject}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmModal;
