import classNames from "classnames";
import { useSelector } from "react-redux";
import castle from "../assets/images/castle-nest.png";
import Nest from "./Nest";
import { activeStakeBirdsSelector } from "./selector";
import styles from "./QuadCastleWrapper.module.scss";
import { NEST_NAMES } from "../store/nestSlice";

const QuadCastleWrapper = () => {
  const { inventory } = useSelector((state) => state.nft);
  const {
    q1: superEagles1,
    q2: superEagles2,
    q3: superEagles3,
    q4: superEagles4,
  } = useSelector((state) => activeStakeBirdsSelector(state));

  const isWrapperEnabled = inventory.find(
    (nest) => nest.name === "Castle Quad Nest"
  );

  return (
    <div
      className={classNames("main-wrapper-1", styles.forthWrapper, {
        active: isWrapperEnabled,
      })}
    >
      {!isWrapperEnabled && <h3>No Access</h3>}

      <img src={castle} className="img-fluid main-bg main-bg" alt="nest" />
      <div className={styles.birdNest}>
        <div className={styles.firstNest}>
          <Nest
            firstBird={superEagles1.nfts?.[0]}
            secondBird={superEagles1.nfts?.[1]}
            duration={superEagles1.duration}
            remainHours={superEagles1.remainHours}
            nestName={NEST_NAMES.CASTLE.q1}
          />
        </div>
        <div className={styles.secondNest}>
          <Nest
            firstBird={superEagles2.nfts?.[0]}
            secondBird={superEagles2.nfts?.[1]}
            duration={superEagles2.duration}
            remainHours={superEagles2.remainHours}
            nestName={NEST_NAMES.CASTLE.q2}
          />
        </div>
        <div className={styles.thirdNest}>
          <Nest
            firstBird={superEagles3.nfts?.[0]}
            secondBird={superEagles3.nfts?.[1]}
            duration={superEagles3.duration}
            remainHours={superEagles3.remainHours}
            nestName={NEST_NAMES.CASTLE.q3}
          />
        </div>

        <div className={styles.forthNest}>
          <Nest
            firstBird={superEagles4.nfts?.[0]}
            secondBird={superEagles4.nfts?.[1]}
            duration={superEagles4.duration}
            remainHours={superEagles4.remainHours}
            nestName={NEST_NAMES.CASTLE.q4}
          />
        </div>
      </div>
    </div>
  );
};

export default QuadCastleWrapper;
