import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { XCircle } from "feather-icons-react";
import { setSelectedInventoryItem } from "../../../store/inventoryPotionsSlice";
import UseInventoryPotionModal from "../UseInventoryPotionModal";

const InventoryPotions = () => {
  const dispatch = useDispatch();

  const { usePotionModalVisible, selected } = useSelector(
    (state: any) => state.inventory
  );
  const inventory = useSelector((state: any) => state.inventory.inventory);

  // remove selected when component unmount
  useEffect(() => {
    return () => {
      dispatch(setSelectedInventoryItem({}));
    };
  }, [dispatch]);
  return (
    <React.Fragment>
      <ul className="nft-ul">
        {inventory?.map(
          ({ item, id }: { item: { [x: string]: string }; id: string }) => {
            return (
              <div
                key={id}
                onClick={() => {
                  dispatch(setSelectedInventoryItem({ id, item }));
                }}
                className="cursor-pointer"
              >
                {selected.id === id && (
                  <XCircle
                    className="closeIcon"
                    onClick={(e: Event) => {
                      e.stopPropagation();
                      dispatch(setSelectedInventoryItem({}));
                    }}
                  />
                )}
                <li className={`tooltip `}>
                  <img
                    src={item.image}
                    alt={item.name}
                    className="img-fluid "
                  />
                  <span className="tooltiptext">{item.name}</span>
                </li>
              </div>
            );
          }
        )}
      </ul>
      {usePotionModalVisible && <UseInventoryPotionModal />}
    </React.Fragment>
  );
};

export default InventoryPotions;
