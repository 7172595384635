import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import qrBackground from "../assets/images/qr-border.png";
import eagle from "../assets/images/smoking-eagle.gif";
import "../assets/scss/pages/login.scss";
import HTTPService from "../common/httpService";
import SeoMetaTags from "../components/SeoMetaTags";

import { loginWithXUMMAPI } from "../common/api-endpoints";
import { localStorageKeys, password, xrpAddress } from "../common/constants";
import { login, verifyXUMM } from "../store/authSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [QRCode, setQRCode] = useState();
  const [link, setLink] = useState();
  const { isLoggedIn } = useSelector((state) => state.auth);

  const handleOnClick = () => {
    HTTPService.post(loginWithXUMMAPI, {})
      .then((res) => {
        if (res.status === 1) {
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            setLink(res.data.next.always);
          } else {
            setQRCode(res.data.refs.qr_png);
          }

          enableWebSocket(res.data.refs.websocket_status);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/home", { replace: true });
    } else {
      // auto login on localhost
      if (process.env.NODE_ENV === "development") {
        dispatch(login({ xrpAddress, password }))
          .then((res) => {
            localStorage.setItem(
              localStorageKeys.isLoggedIn,
              JSON.stringify(res.payload)
            );
            setTimeout(() => {
              navigate("/start", { replace: true });
            }, 3000);
          })
          .catch((e) => {
            console.log("Error", e);
            window.location.reload();
          });
      }
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) navigate("/home", { replace: true });
  }, [isLoggedIn]);

  const enableWebSocket = (url) => {
    if (url) {
      const webSocket = new WebSocket(url);

      webSocket.onopen = () => {
        console.log("WebSocket Client Connected");
      };

      webSocket.onmessage = (message) => {
        let { data } = message;
        data = JSON.parse(data);
        if (data.payload_uuidv4) {
          setTimeout(() => {
            dispatch(verifyXUMM({ payloadId: data.payload_uuidv4 }))
              .then((res) => {
                localStorage.setItem(
                  localStorageKeys.isLoggedIn,
                  JSON.stringify(res.payload)
                );
                navigate("/start", { replace: true });
              })
              .catch((e) => {
                console.log("Error", e);
                window.location.reload();
              });
          }, 2000);
        }
      };
      webSocket.onclose = (event) => {
        console.log("Connection closed", event);
      };
    }
  };

  const openLink = () => {
    window.open(link, "_blank");
  };

  return (
    <div className="home-wrapper">
      <SeoMetaTags title={"Login"} />
      <div className="logo-wrapper">
        <div className="left-portion">
          <img src={logo} className="img-fluid logo-img" alt="logo" />
          <h1 className="title">Welcome to Super Eagle</h1>
        </div>
        <div className="right-portion">
          <div className="position-relative">
            <img src={qrBackground} className="img-fluid qr-img" alt="qrcode" />
            <div className="button-wrapper">
              <button
                className="mt-0 nes-btn is-error"
                onClick={link ? openLink : handleOnClick}
              >
                {link ? "Open XUMM link" : "Connect to XRP Wallet"}
              </button>
            </div>
            {QRCode && (
              <div className="qr-wrapper">
                <img src={QRCode} className="img-fluid " alt="mainqr" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="about-us-wrapper">
        <div className="left-ear">
          <svg
            width="17"
            height="54"
            viewBox="0 0 17 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="10.8008"
              y="5.39844"
              width="5.4"
              height="5.4"
              transform="rotate(180 10.8008 5.39844)"
              fill="#9DFFA7"
            />
            <rect
              x="5.40039"
              y="10.8008"
              width="5.4"
              height="5.4"
              transform="rotate(180 5.40039 10.8008)"
              fill="#9DFFA7"
            />
            <rect
              x="16.2012"
              y="54"
              width="5.4"
              height="54"
              transform="rotate(180 16.2012 54)"
              fill="black"
            />
            <rect
              x="10.8008"
              y="48.6016"
              width="5.4"
              height="43.2"
              transform="rotate(180 10.8008 48.6016)"
              fill="black"
            />
            <rect
              x="5.40039"
              y="31.5"
              width="5.4"
              height="20.7"
              transform="rotate(180 5.40039 31.5)"
              fill="black"
            />
          </svg>
        </div>
        <div className="right-ear">
          <svg
            width="17"
            height="54"
            viewBox="0 0 17 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="16.1992"
              y="24.3008"
              width="5.4"
              height="5.4"
              transform="rotate(180 16.1992 24.3008)"
              fill="#9DFFA7"
            />
            <rect
              x="5.39844"
              y="48.6016"
              width="5.4"
              height="5.4"
              fill="#969696"
            />
            <rect
              x="10.7988"
              y="43.1992"
              width="5.4"
              height="5.4"
              fill="#969696"
            />
            <rect width="5.4" height="54" fill="black" />
            <rect
              x="5.40039"
              y="5.39844"
              width="5.4"
              height="43.2"
              fill="black"
            />
            <rect
              x="10.8008"
              y="24.3008"
              width="5.4"
              height="18.9"
              fill="black"
            />
          </svg>
        </div>
        <div className="content">
          <h2>About The Game</h2>
          <div className="content-portion">
            <div className="left-img">
              <img src={eagle} className="img-fluid" alt="margh" />
            </div>
            <div className="right-content">
              <h4 className="tx-fifth secondary-font">
                SuperEagles demand regulatory clarity for all cryptocurrencies
                and they won’t be stopping their demonstration till they get it.
                Build your nest and roost your SuperEagles in this revolutionary
                idle NFT generation game on the XRP ledger.
              </h4>
              <h4 className="tx-fifth secondary-font mt-4">
                Owning at least two SuperEagles allows you to generate BabyEagle
                NFTs which will carry on the future fight for fair and sensible
                regulation for crypto. Staking your SuperEagles in your nest
                over time generates SuperEagle Coin ($SEC) which you can spend
                in game to afford potions, upgrade your nest and more.
              </h4>
              <h4 className="tx-fifth primary-font mt-4 from-tl">
                Team Super Eagle
              </h4>
            </div>
          </div>
        </div>
        <div className="bottom-ear">
          <svg
            width="70"
            height="14"
            viewBox="0 0 70 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="70" height="4.66667" fill="black" />
            <rect
              x="12.832"
              y="4.66797"
              width="57.1667"
              height="4.66667"
              fill="black"
            />
            <rect
              x="12.832"
              y="9.33203"
              width="57.1667"
              height="4.66667"
              fill="black"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Login;
