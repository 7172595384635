import React, { useEffect, useState } from "react";
import { ShopPageItem, Loader } from "../../components";
import HTTPService from "../../common/httpService";
import { colors } from "../../common/constants";
import { itemsAPI } from "../../common/api-endpoints";
import SeoMetaTags from "../../components/SeoMetaTags";
import "../../assets/scss/pages/shop.scss";

const Shop = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    HTTPService.get(itemsAPI)
      .then((res) => {
        if (res.data.results.length) {
          setItems(res.data.results);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    setLoading(!loading);
  }, []);

  return (
    <React.Fragment>
      {" "}
      <SeoMetaTags title={"Shop"} />
      {loading ? (
        <Loader />
      ) : (
        <div className="common-lap shop-main-wrapper">
          <div className="title-block">
            <h3 className="tx-twelve">Shop</h3>
          </div>
          <div className="common-inner-lap">
            <div className="eagle-shop">
              {items.map((item, i) => (
                <ShopPageItem
                  key={i}
                  background={`bg-${
                    colors[Math.floor(Math.random() * colors.length)]
                  }`}
                  item={item}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Shop;
