import { useSelector } from "react-redux";
import basic from "../assets/images/nest.gif";
import { NEST_WRAPPERS } from "../common/constants";
import Nest from "./Nest";
import { activeStakeBirdsSelector } from "./selector";

const BasicWrapper = () => {
  const { s: superEagles } = useSelector((state) =>
    activeStakeBirdsSelector(state, NEST_WRAPPERS.DEFAULT)
  );

  return (
    <div className="main-wrapper-1 active">
      <img src={basic} className="img-fluid main-bg main-bg" alt="nest" />
      <div className="bird-nest">
        <Nest
          firstBird={superEagles.nfts?.[0]}
          secondBird={superEagles.nfts?.[1]}
          duration={superEagles.duration}
          remainHours={superEagles.remainHours}
        />
      </div>
    </div>
  );
};

export default BasicWrapper;
