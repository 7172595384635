import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommunityAPI,
  removeCommunityAPI,
} from "../../common/api-endpoints";
import HTTPService from "../../common/httpService";
import { fetchCommunity } from "../../store/allPlayersSlice";
import { allPlayersSelectors } from "../../store/selectors";
import SeoMetaTags from "../../components/SeoMetaTags";
import PlayerItem from "./PlayerItem";
import AllPlayersPagination from "./AllPlayersPagination";
import "../../assets/scss/components/community.scss";

const Players = ({ bgColor }) => {
  const dispatch = useDispatch();

  const [favourite, setFavourite] = useState([]);

  const handleAddFavoruite = (index, stakeId) => {
    setFavourite((friends) => {
      let temp = [...friends];
      temp[index] = true;
      return temp;
    });
    HTTPService.post(addCommunityAPI, {
      stakeId: stakeId,
    })
      .then((res) => {
        dispatch(fetchCommunity());
        toast.success(res.message);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleRemoveFavoruite = (index, stakeId) => {
    setFavourite((friends) => {
      let temp = [...friends];
      temp[index] = false;
      return temp;
    });
    HTTPService.post(removeCommunityAPI, {
      stakeId: stakeId,
    })
      .then((res) => {
        dispatch(fetchCommunity());
        toast.success(res.message);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const { players: allPlayers, loading } = useSelector(allPlayersSelectors);

  return (
    <React.Fragment>
      <SeoMetaTags title={"All Players"} />
      {allPlayers.length > 0 ? (
        allPlayers.map((player, index) => {
          return (
            <PlayerItem
              player={player}
              key={player.id}
              addFavourite={handleAddFavoruite}
              removeFavourite={handleRemoveFavoruite}
              index={index}
              favourite={favourite}
            />
          );
        })
      ) : loading ? (
        <p>Loading.....</p>
      ) : (
        <p>No Players found!</p>
      )}
      <AllPlayersPagination />
    </React.Fragment>
  );
};

export default Players;
