import { useFormik } from "formik";
import { useRef } from "react";
import * as Yup from "yup";
import "../assets/scss/components/modal.scss";
import { reportBugAPI } from "../common/api-endpoints";
import { urlRegex, fileFormats } from "../common/constants";
import HTTPService from "../common/httpService";
const BugModal = ({ bugModal }) => {
  const ref = useRef();

  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Title is required")
      .min(4, "Title should be minimum 4 character long")
      .max(50, "Maximum 50 character are allowed"),
    description: Yup.string()
      .required("Description is required")
      .min(4, "Description should be minimum 4 character long")
      .max(150, "Maximum 150 character are allowed"),
    url: Yup.string()
      .matches(urlRegex, "URL must be valid")
      .required("URL is required"),
    file: Yup.mixed()
      .test("fileType", "Only PNG, JPG, JPEG, MP4 files allowed", (file) =>
        file ? fileFormats.includes(file.type) : true
      )
      .required("File is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      url: "",
      file: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      if (values.url) {
        formData.append("sourceUrl", values.url);
      }
      formData.append("screenshot", values.file);
      handleOnSubmit(formData);
    },
  });

  const handleOnSubmit = (data) => {
    HTTPService.post(reportBugAPI, data)
      .then((res) => {
        bugModal();
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  const handleFileOpen = () => {
    ref.current.click();
  };

  const { errors, touched, submitForm, values, setFieldValue } = formik;

  const handleChange = (event) => {
    if (event.target.name === "file") {
      setFieldValue(event.target.name, event.target.files[0]);
    } else {
      setFieldValue(event.target.name, event.target.value);
    }
  };

  return (
    <>
      <form>
        <div className="modal-backdrop"></div>
        <div className="modal-wrapper">
          <div className="modal-content bugmodal">
            <a
              type="button"
              className="custom-button mt-0 close"
              onClick={bugModal}
            >
              &times;
            </a>
            <br />
            <h4 className="tx-sixth mb-2">Report a Bug</h4>
            <div className="input-wrapper">
              <div className="nes-field ">
                <label htmlFor="name_field">Title</label>
              </div>
              <input
                type="text"
                id="name_field"
                className={`custom-input mb-2 ${
                  errors.title && touched.title ? `nes-input is-error` : ""
                }`}
                name="title"
                value={values.title}
                onChange={(e) => handleChange(e)}
                maxLength="50"
              />
              {errors.title && touched.title && (
                <span className="nes-text is-error">{errors.title}</span>
              )}
            </div>
            <div className="input-wrapper">
              <div className="nes-field ">
                <label htmlFor="name_field">Description</label>
              </div>
              <textarea
                id="textarea_field"
                className="custom-input mb-2"
                name="description"
                value={values.description}
                onChange={(e) => handleChange(e)}
                maxLength="150"
              ></textarea>
              {errors.description && touched.description && (
                <span className="nes-text is-error">{errors.description}</span>
              )}
            </div>
            <div className="input-wrapper ">
              <div className="nes-field ">
                <label htmlFor="name_field">Url</label>
              </div>
              <input
                type="text"
                id="name_field"
                className={`custom-input mb-2 ${
                  errors.url && touched.url ? `nes-input is-error` : ""
                }`}
                name="url"
                value={values.url}
                onChange={(e) => handleChange(e)}
              />
              {errors.url && touched.url && (
                <span className="nes-text is-error">{errors.url}</span>
              )}
            </div>
            <div className="input-wrapper">
              <div className="nes-field ">
                <label htmlFor="name_field">Screenshot</label>
              </div>

              <div className="custom-file-input mb-2" onClick={handleFileOpen}>
                <svg
                  width="34"
                  height="25"
                  viewBox="0 0 34 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.14296 8.14185C5.92858 3.62923 9.86342 0.199219 14.6003 0.199219C17.7688 0.199219 20.5778 1.73488 22.3249 4.0984C22.9155 3.9042 23.5463 3.7992 24.2002 3.7992C27.1922 3.7992 29.6723 5.98896 30.126 8.85394C32.342 10.3644 33.8002 12.9109 33.8002 15.7995C33.8002 20.4388 30.0395 24.1995 25.4002 24.1995H18.2002V17.4967L19.7516 19.0481C20.2204 19.5166 20.98 19.5166 21.4488 19.0481C21.9172 18.5794 21.9172 17.8197 21.4488 17.351L17.8488 13.751C17.38 13.2825 16.6204 13.2825 16.1516 13.751L12.5516 17.351C12.0831 17.8197 12.0831 18.5794 12.5516 19.0481C13.0204 19.5166 13.78 19.5166 14.2488 19.0481L15.8002 17.4967V24.1995H8.60019C3.96093 24.1995 0.200195 20.4388 0.200195 15.7995C0.200195 12.3911 2.2295 9.45963 5.14296 8.14217V8.14185Z"
                    fill="black"
                  />
                </svg>
                <p className="mt-1 mb-0">Drag Your Image Here</p>
              </div>
              <input
                type="file"
                id="name_field"
                className="custom-input mb-2"
                name="file"
                style={{ display: "none" }}
                ref={ref}
                onChange={handleChange}
                accept="image/png, image/jpg, image/jpeg, video/mp4"
              />
              {errors.file && touched.file && (
                <span className="nes-text is-error">{errors.file}</span>
              )}
            </div>
            <br />
            <div className="text-center">
              <button
                type="button"
                className="custom-button mt-0 nes-btn is-error"
                onClick={submitForm}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default BugModal;
