import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { activeStakeAPI } from "../common/api-endpoints";
import { NEST_WRAPPERS } from "../common/constants";
import HTTPService from "../common/httpService";
import { getStakeTime } from "../utils/getStakeTime";
import { NEST_NAMES } from "./nestSlice";

export const fetchActiveStakes = createAsyncThunk(
  "fetchActiveStakes",
  async (_, thunkAPI) => {
    const {
      nft: { nfts },
      nest: { nests },
    } = thunkAPI.getState();

    return await HTTPService.get(activeStakeAPI, {})
      .then((res) => {
        if (res.status === 1 && res.data.length) {
          return res.data.reduce((acc, stake) => {
            // find the position of stake nest
            const nestKey = nests
              .find((nest) => stake.nest === nest.id)
              ?.position?.toLowerCase();
            // calculate timeboard time ahead so no later calculations required
            const { duration, startTime, endTime, remainSeconds, remainHours } =
              getStakeTime(stake);

            return {
              ...acc,
              [nestKey]: {
                ...stake,
                nfts: stake.tokens.map((tokenId) =>
                  nfts.find(({ stakeValue }) => tokenId === stakeValue)
                ),
                startTime,
                endTime,
                remainHours,
                remainSeconds,
                duration,
              },
            };
          }, {});
        } else return {};
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue("Could not fetch active stakes");
      });
  }
);

export const activeStakesSlice = createSlice({
  name: "activeStakes",
  initialState: {
    activeStakes: [],
    activeStakesByName: {
      [NEST_WRAPPERS.DEFAULT]: { s: {} },
      [NEST_WRAPPERS.DOUBLE_STORY]: {
        d1: {},
        d2: {},
      },
      [NEST_WRAPPERS.PIRATE_SHIP]: {
        t1: {},
        t2: {},
        t3: {},
      },
      [NEST_WRAPPERS.CASTLE]: {
        q1: {},
        q2: {},
        q3: {},
        q4: {},
      },
    },
    stakeLoading: false,
    canDoNewStake: false,
  },
  reducers: {
    updateActiveStakeByName: (state, action) => {
      const { wrapper, nest, nfts } = action.payload;
      state.activeStakesByName[wrapper][nest].nfts = nfts;
      if (nfts?.length > 1) {
        // if user selects new nfts activate new stake
        state.canDoNewStake = true;
      } else {
        state.canDoNewStake = false;
      }
    },
    setCanDoNewStake: (state, action) => {
      state.canDoNewStake = action.payload;
    },
  },
  extraReducers: {
    [fetchActiveStakes.fulfilled]: (state, action) => {
      state.stakeLoading = false;
      state.activeStakes = Object.values(action.payload || {}) || [];
      state.activeStakesByName = {
        [NEST_WRAPPERS.DEFAULT]: {
          s: action.payload[NEST_NAMES.DEFAULT.s] || {},
        },
        [NEST_WRAPPERS.DOUBLE_STORY]: {
          d1: action.payload?.[NEST_NAMES.DOUBLE_STORY.d1] || {},
          d2: action.payload?.[NEST_NAMES.DOUBLE_STORY.d2] || {},
        },
        [NEST_WRAPPERS.PIRATE_SHIP]: {
          t1: action.payload?.[NEST_NAMES.PIRATE_SHIP.t1] || {},
          t2: action.payload?.[NEST_NAMES.PIRATE_SHIP.t2] || {},
          t3: action.payload?.[NEST_NAMES.PIRATE_SHIP.t3] || {},
        },
        [NEST_WRAPPERS.CASTLE]: {
          q1: action.payload?.[NEST_NAMES.CASTLE.q1] || {},
          q2: action.payload?.[NEST_NAMES.CASTLE.q2] || {},
          q3: action.payload?.[NEST_NAMES.CASTLE.q3] || {},
          q4: action.payload?.[NEST_NAMES.CASTLE.q4] || {},
        },
      };
    },
    [fetchActiveStakes.pending]: (state) => {
      state.stakeLoading = true;
    },
    [fetchActiveStakes.rejected]: (state) => {
      state.stakeLoading = false;
    },
  },
});

export const { updateActiveStakeByName, setCanDoNewStake } =
  activeStakesSlice.actions;

export default activeStakesSlice.reducer;
