import React from "react";
import Modal from "react-modal";
import "../assets/scss/components/withdrawModal.scss";

const OpenInXumOrNewWindowModal = ({
  qrCodeImage,
  xummLink,
  modalIsOpen,
  closeModal,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Open In XUMM modal"
      className="withdraw-modal"
      overlayClassName="withdraw-modal-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="inner-wrapper">
        <div className="close-button" onClick={closeModal}>
          X
        </div>
        <img src={qrCodeImage} className="img-fluid w-100" alt="" />
        <p>OR</p>
        <a href={xummLink} target="_blank" rel="noreferrer">
          <button type="button" className=" mt-0 nes-btn is-error">
            Open in XUMM
          </button>
        </a>
      </div>
    </Modal>
  );
};

export default OpenInXumOrNewWindowModal;
