import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { transactionListAPI } from "../common/api-endpoints";
import HTTPService from "../common/httpService";

export const fetchAllTransactions = createAsyncThunk(
  "fetchAllTransactions",
  async (thunkAPI) => {
    let data = [];
    await HTTPService.get(transactionListAPI)
      .then((res) => {
        data = res.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue("Cannot fetch transactions");
      });
    return data;
  }
);

export const allTransactions = createSlice({
  name: "allTransactions",
  initialState: { transactions: [], loading: false },
  reducers: {
    addTransactions: (state, action) => {
      state.players = action.payload;
    },
  },
  extraReducers: {
    [fetchAllTransactions.fulfilled]: (state, action) => {
      state.transactions = action.payload;
      state.loading = false;
    },
    [fetchAllTransactions.pending]: (state) => {
      state.loading = true;
    },
  },
});

export const { addTransactions } = allTransactions.actions;

export default allTransactions.reducer;
