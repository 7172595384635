import { useEffect, useMemo } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import dayMusic from "../assets/music/day-music.wav";
import nightMusic from "../assets/music/night-music.wav";
import { updateMusic } from "../store/authSlice";
import { NEST_WRAPPERS } from "../common/constants";
import { useLocation } from "react-router-dom";

const castleMusicSrc =
  "https://supereaglebucket.s3.amazonaws.com/Music/scoin-medevial.mp3";
const pirateShipMusicSrc =
  "https://supereaglebucket.s3.amazonaws.com/Music/scoin-pirate.mp3";
const defaultMusic =
  moment().hour() >= 18 && (moment().hour() <= 24 || moment().hour() <= 6)
    ? nightMusic
    : dayMusic;

const usePlayMusic = () => {
  const { pathname } = useLocation() || {};
  const dispatch = useDispatch();

  const { isMusicPlaying } = useSelector((state: any) => state.auth);
  const { activeNestWrapper } = useSelector((state: any) => state.nest);

  const audio = useMemo(() => new Audio(), []);

  useEffect(() => {
    if (
      activeNestWrapper === NEST_WRAPPERS.PIRATE_SHIP &&
      pathname === "/home"
    ) {
      audio.setAttribute("src", pirateShipMusicSrc);
    } else if (
      activeNestWrapper === NEST_WRAPPERS.CASTLE &&
      pathname === "/home"
    ) {
      audio.setAttribute("src", castleMusicSrc);
    } else {
      audio.setAttribute("src", defaultMusic);
    }
    audio.load();

    if (isMusicPlaying) {
      audio.play();
    }

    return () => {
      audio.pause();
    };
  }, [activeNestWrapper, audio, isMusicPlaying, pathname]);

  audio.loop = true;

  const playAppMusic = () => {
    dispatch(updateMusic());
    if (!isMusicPlaying) {
      audio.play();
    } else {
      audio.currentTime = 0;
      audio.pause();
    }
  };

  return { playAppMusic };
};

export default usePlayMusic;
