export const ClearNotificationSVG = (
  <svg
    width="64"
    height="45"
    viewBox="0 0 64 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.853516"
      y="24.5156"
      width="3.55188"
      height="3.55188"
      transform="rotate(-71.4013 0.853516 24.5156)"
      fill="black"
    />
    <rect
      x="19.3682"
      y="30.7461"
      width="3.55188"
      height="3.55188"
      transform="rotate(-71.4013 19.3682 30.7461)"
      fill="black"
    />
    <rect
      x="18.8184"
      y="26.8135"
      width="3.55188"
      height="3.55188"
      transform="rotate(-71.4013 18.8184 26.8135)"
      fill="black"
    />
    <rect
      x="3.66895"
      y="21.7158"
      width="3.55188"
      height="3.55188"
      transform="rotate(-71.4013 3.66895 21.7158)"
      fill="black"
    />
    <rect
      x="6.48535"
      y="18.916"
      width="3.55188"
      height="3.55188"
      transform="rotate(-71.4013 6.48535 18.916)"
      fill="black"
    />
    <rect
      x="18.2676"
      y="22.8809"
      width="3.55188"
      height="3.55188"
      transform="rotate(-71.4013 18.2676 22.8809)"
      fill="black"
    />
    <rect
      x="17.7178"
      y="18.9482"
      width="3.55188"
      height="3.55188"
      transform="rotate(-71.4013 17.7178 18.9482)"
      fill="black"
    />
    <rect
      x="9.30176"
      y="16.1162"
      width="3.55188"
      height="3.55188"
      transform="rotate(-71.4013 9.30176 16.1162)"
      fill="black"
    />
    <rect
      x="12.668"
      y="17.248"
      width="3.55188"
      height="5.32782"
      transform="rotate(-71.4013 12.668 17.248)"
      fill="#676767"
    />
    <rect
      x="9.85156"
      y="20.0488"
      width="3.55188"
      height="8.8797"
      transform="rotate(-71.4013 9.85156 20.0488)"
      fill="#676767"
    />
    <rect
      x="7.03613"
      y="22.8486"
      width="3.55188"
      height="12.4316"
      transform="rotate(-71.4013 7.03613 22.8486)"
      fill="#676767"
    />
    <rect
      x="11.2764"
      y="13.0332"
      width="3.55188"
      height="10.6556"
      transform="rotate(-71.4013 11.2764 13.0332)"
      fill="black"
    />
    <rect
      x="9.04199"
      y="8.5332"
      width="5.32782"
      height="55.4981"
      transform="rotate(-71.4013 9.04199 8.5332)"
      fill="black"
    />
    <rect
      x="4.21973"
      y="25.6484"
      width="3.55188"
      height="15.9835"
      transform="rotate(-71.4013 4.21973 25.6484)"
      fill="#676767"
    />
    <rect
      x="3.65332"
      y="27.332"
      width="3.55188"
      height="15.9835"
      transform="rotate(-71.4013 3.65332 27.332)"
      fill="black"
    />
    <rect
      x="14.8037"
      y="6.72461"
      width="3.55188"
      height="7.99173"
      transform="rotate(-71.4013 14.8037 6.72461)"
      fill="black"
    />
  </svg>
);
