import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HTTPService from "../../common/httpService";
import {
  Loader,
  TimeBoard,
  AcceptanceModal,
  ConfirmModal,
} from "../../components";
import BasicWrapper from "../../components/BasicWrapper";
import QuadCastleWrapper from "../../components/QuadCastleWrapper";
import TripleNestPirateShipWrapper from "../../components/TripleNestPirateShipWrapper";
import DoubleStoryBirdBoxWrapper from "../../components/DoubleStoryBirdBoxWrapper";
import { stakeAPI, stopStakeAPI } from "../../common/api-endpoints";
import { fetchAccountsNFTs } from "../../store/nftSlice";
import SeoMetaTags from "../../components/SeoMetaTags";
import GreetingsBoard from "../../components/GreetingsBoard";
import { fetchAllNests, NEST_NAMES, setStaking } from "../../store/nestSlice";
import "../../assets/scss/pages/home.scss";
import Inventory from "./Inventory";
import StakeButton from "./StakeButton";
import NestPagination from "./NestPagination";
import { fetchActiveStakes } from "../../store/activeStakeSlice";
import { NEST_WRAPPERS } from "../../common/constants";
import StakeInfoPopup from "./StakeInfoPopup";
import { toast } from "react-hot-toast";
import { fetchInventory } from "../../store/inventoryPotionsSlice";

export const useBoostrapApplicationHome = (user) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Alert: This order is important
    // myNfts and nests details are needed to calculate activeStakes normalized state
    // do not change the order until you are aware what you are doing.
    dispatch(fetchAccountsNFTs({ userXrpAddress: user.xrpAddress })).then(
      () => {
        dispatch(fetchAllNests(user.id)).then(() =>
          dispatch(fetchActiveStakes())
        );
      }
    );
    dispatch(fetchInventory());
  }, [dispatch, user.id, user.xrpAddress]);
};

const Home = () => {
  const dispatch = useDispatch();

  const [acceptance, setAcceptance] = useState(false);
  const [isStaking, setIsStaking] = useState(false);
  const [stakeInfoModal, setStakeInfoModal] = useState(false);
  const [isConfirmModelVisible, setIsConfirmModelVisible] = useState(false);
  const [resume, setResume] = useState(null);

  const {
    auth: { user },
    nest: { nests, activeNestWrapper, activeNest },
    stake: { activeStakesByName },
  } = useSelector((state) => state);

  useBoostrapApplicationHome(user);

  useEffect(() => {
    if (resume != null) {
      setIsConfirmModelVisible(!isConfirmModelVisible);
    }
  }, [resume]);

  const handleResumeStake = (status) => {
    setResume(status);
  };

  const handleOnSubmit = () => {
    setIsConfirmModelVisible(!isConfirmModelVisible);
    if (resume) {
      const nfts = activeStakesByName[activeNestWrapper][activeNest]?.nfts;
      const nestId = nests.find(
        (nest) => nest.position.toLowerCase() === activeNest
      )?.id;

      HTTPService.post(stakeAPI, {
        token1: nfts[0].stakeValue,
        token2: nfts[1].stakeValue,
        nestId,
      })
        .then((res) => {
          setAcceptance(!acceptance);
          dispatch(setStaking(true));
        })
        .catch((e) => {
          console.log("Error", e);
        });
    } else {
      const stakeId = activeStakesByName[activeNestWrapper][activeNest]?.id;

      HTTPService.post(stopStakeAPI, {
        stakeId,
      })
        .then((res) => {
          setAcceptance(!acceptance);
          dispatch(setStaking(false));
        })
        .catch((e) => {
          console.log("Error", e);
        });
    }
  };

  return (
    <React.Fragment>
      <SeoMetaTags title={"Home"} />
      {/* {loading || nftsLoading ? (
        <Loader />
      ) : ( */}
      <div className="main-component">
        <div className="custom-home-wrapper">
          <div className={`mobile-show-counter`}>
            {window.innerWidth < 767 && <TimeBoard />}
          </div>
          <GreetingsBoard title={"Welcome to the world of SuperEagle Coin!"} />
          {activeNestWrapper === NEST_WRAPPERS.DEFAULT && <BasicWrapper />}
          {activeNestWrapper === NEST_WRAPPERS.DOUBLE_STORY && (
            <DoubleStoryBirdBoxWrapper />
          )}
          {activeNestWrapper === NEST_WRAPPERS.PIRATE_SHIP && (
            <TripleNestPirateShipWrapper />
          )}
          {activeNestWrapper === NEST_WRAPPERS.CASTLE && <QuadCastleWrapper />}
          <NestPagination />
        </div>
        <div className="right-sidebar">
          <div className="mobile-hide-counter">
            {window.innerWidth > 767 && <TimeBoard />}
          </div>
          <Inventory />
          <StakeButton
            handleStakeClick={(value) => {
              if (activeNest) {
                setIsStaking(value);
                setStakeInfoModal(true);
              } else {
                toast.error("Please select a nest first");
              }
            }}
          />
        </div>
        {isConfirmModelVisible && (
          <ConfirmModal
            handleOnSubmit={handleOnSubmit}
            handleConfirmModal={() =>
              setIsConfirmModelVisible(!isConfirmModelVisible)
            }
            resume={resume}
            handleResumeStake={handleResumeStake}
          />
        )}
        {acceptance && <AcceptanceModal resume={resume} />}
        {stakeInfoModal && (
          <StakeInfoPopup
            isOpen={true}
            isStaking={isStaking}
            onOk={() => {
              setStakeInfoModal(false);
              handleResumeStake(isStaking);
            }}
            onClose={() => {
              setStakeInfoModal(false);
            }}
          />
        )}
      </div>
      {/* )} */}
    </React.Fragment>
  );
};

export default Home;
