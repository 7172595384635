import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { accountNFTAPI } from "../common/api-endpoints";
import HTTPService from "../common/httpService";
import { updateUser } from "./authSlice";

export const fetchAccountsNFTs = createAsyncThunk(
  "fetchAccountsNFTs",
  async ({ userXrpAddress }, thunkAPI) => {
    let response = { nfts: [], inventory: [] };

    await HTTPService.post(accountNFTAPI, {
      xrpaddress: userXrpAddress,
    })
      .then((res) => {
        // update user coins to latest
        if (res.data.token.value) {
          thunkAPI.dispatch(updateUser({ coins: res.data.token.value }));
        }
        if (res.data.nft.length) {
          for (let i = 0; i < res.data.nft.length; i++) {
            const nft = {
              name: res.data.nft[i].name,
              url: res.data.nft[i].pixel_image,
              issuer: res.data.nft[i].issuer,
              stakeValue: res.data.nft[i].stakeValue,
              aliasName: res.data.nft[i].aliasName,
              isStaked: res.data.nft[i].isStaked,
            };
            response.nfts.push(nft);
          }
        }
        if (response.inventory) response.inventory = res.data.inventory;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e);
      });
    return response;
  }
);

export const nftSlice = createSlice({
  name: "nft",
  initialState: { nfts: [], nftsLoading: false, inventory: [] },
  reducers: {
    updateNFT: (state, action) => {
      const { index, ...rest } = action.payload;
      state.nfts[index] = {
        ...state.nfts[index],
        ...rest,
      };
    },
  },
  extraReducers: {
    [fetchAccountsNFTs.fulfilled]: (state, action) => {
      state.nfts = action.payload.nfts;
      state.inventory = action.payload.inventory;
      state.nftsLoading = false;
    },
    [fetchAccountsNFTs.pending]: (state) => {
      state.nftsLoading = true;
    },
    [fetchAccountsNFTs.rejected]: (state) => {
      state.nftsLoading = false;
    },
  },
});

export const { updateNFT } = nftSlice.actions;

export default nftSlice.reducer;
