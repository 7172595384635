import classNames from "classnames";
import { useSelector } from "react-redux";
import home from "../assets/images/home-nest.gif";
import Nest from "./Nest";
import styles from "./DoubleStoryBirdBoxWrapper.module.scss";
import { activeStakeBirdsSelector } from "./selector";
import { NEST_WRAPPERS } from "../common/constants";
import { NEST_NAMES } from "../store/nestSlice";

const DoubleStoryBirdBoxWrapper = () => {
  const { inventory } = useSelector((state) => state.nft);
  const { d1: superEagles1, d2: superEagles2 } = useSelector((state) =>
    activeStakeBirdsSelector(state, NEST_WRAPPERS.DOUBLE_STORY)
  );

  const isWrapperEnabled = inventory.find(
    (nest) => nest.name === "Double Story Bird Box"
  );

  return (
    <div
      className={classNames("main-wrapper-1", styles.wrapper, {
        active: isWrapperEnabled,
      })}
    >
      {!isWrapperEnabled && <h3>No Access</h3>}
      <img src={home} className="img-fluid main-bg main-bg" alt="nest" />
      <div className={styles.birdNest}>
        <div className={styles.firstNest}>
          <Nest
            firstBird={superEagles1.nfts?.[0]}
            secondBird={superEagles1.nfts?.[1]}
            duration={superEagles1.duration}
            remainHours={superEagles1.remainHours}
            nestName={NEST_NAMES.DOUBLE_STORY.d1}
          />
        </div>

        <div className={styles.secondNest}>
          <Nest
            firstBird={superEagles2.nfts?.[0]}
            secondBird={superEagles2.nfts?.[1]}
            duration={superEagles2.duration}
            remainHours={superEagles2.remainHours}
            nestName={NEST_NAMES.DOUBLE_STORY.d2}
          />
        </div>
      </div>
    </div>
  );
};

export default DoubleStoryBirdBoxWrapper;
