import React, { useEffect, useState } from "react";
import HTTPService from "../../common/httpService";
import { colors } from "../../common/constants";
import { babyNfts } from "../../common/api-endpoints";
import SeoMetaTags from "../../components/SeoMetaTags";
import TimeMachineItem from "./TimeMachineItem";
import "../../assets/scss/pages/shop.scss";
import style from "./TimeMachinePage.module.scss";
import { Loader } from "../../components";

const TimeMachinePage = () => {
  const [loading, setLoading] = useState(true);
  const [chances, setChances] = useState(0);
  const [babyNftItem, setBabyNftItem] = useState([]);

  useEffect(() => {
    HTTPService.get(babyNfts)
      .then((res) => {
        setChances(res.data.totalChance);
        if (res.data.babyNfts.length) {
          setBabyNftItem(res.data.babyNfts);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log("Error", e);
        setLoading(false);
      });
  }, []);

  return (
    <React.Fragment>
      {" "}
      <SeoMetaTags title={"Time Machine"} />
      {loading ? (
        <Loader />
      ) : (
        <div className="common-lap shop-main-wrapper">
          <div className="title-block justify-between d-flex">
            <h3 className="tx-twelve">Time Machine</h3>
            <div className={style.timeMachineLifes} title="chances">
              {chances > 3 ? (
                <>
                  <b className="h4">{chances}</b>
                  <i className="nes-icon is-medium star f-w-bold"></i>
                </>
              ) : (
                Array.from(Array(chances).keys()).map((chance) => {
                  return (
                    <i
                      className="nes-icon is-medium star"
                      key={chance + "2j345jjdjdjj"}
                    ></i>
                  );
                })
              )}
            </div>
          </div>
          <div className="common-inner-lap">
            <div className="eagle-shop">
              {babyNftItem.length > 0 ? (
                babyNftItem.map((item, i) => (
                  <TimeMachineItem
                    key={i}
                    background={`bg-${
                      colors[Math.floor(Math.random() * colors.length)]
                    }`}
                    item={item}
                  />
                ))
              ) : (
                <p className="text-center" style={{ width: "100%" }}>
                  No NFTs found.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TimeMachinePage;
