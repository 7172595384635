import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { allPlayersAPI, communityAPI } from "../common/api-endpoints";
import HTTPService from "../common/httpService";

export const fetchAllPlayers = createAsyncThunk(
  "fetchAllPlayer",
  async (params = {}, thunkAPI) => {
    const { page } = params;
    let data = [];
    await HTTPService.get(`${allPlayersAPI}${page}`)
      .then((res) => {
        data = res.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue();
      });
    return data;
  }
);
export const fetchCommunity = createAsyncThunk(
  "fetchCommunity",
  async (thunkAPI) => {
    let data = [];
    await HTTPService.get(communityAPI)
      .then((res) => {
        data = res.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue();
      });
    return data;
  }
);

export const allPlayers = createSlice({
  name: "allPlayers",
  initialState: { players: [], community: [], loading: false },
  reducers: {
    addPlayers: (state, action) => {
      state.players = action.payload;
    },
    addCommunity: (state, action) => {
      state.community = action.payload;
    },
  },
  extraReducers: {
    [fetchAllPlayers.fulfilled]: (state, action) => {
      state.players = action.payload;
      state.loading = false;
    },
    [fetchAllPlayers.pending]: (state) => {
      state.loading = true;
    },
    [fetchCommunity.pending]: (state) => {
      state.loading = true;
    },
    [fetchCommunity.fulfilled]: (state, action) => {
      state.loading = false;
      state.community = action.payload;
    },
  },
});

export const { addPlayers, addCommunity } = allPlayers.actions;

export default allPlayers.reducer;
