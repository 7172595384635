import { createSelector } from "reselect";

const activeStakeByNameSelector = (state) => {
  const nestWrapperName = state.nest.activeNestWrapper;
  return state.stake.activeStakesByName?.[nestWrapperName];
};

const activeNestNameSelector = (state) => state.nest.activeNest;

export const activeStakeNestsCapacitySelector = createSelector(
  [activeStakeByNameSelector],
  (nests = {}) => {
    let isNestForStakeAvailable = false;
    Object.keys(nests).forEach((key) => {
      if (nests[key]?.staking !== "running") {
        isNestForStakeAvailable = true;
      }
    });

    return isNestForStakeAvailable;
  }
);

export const activeStakeNestSelector = createSelector(
  [activeStakeByNameSelector],
  (nests = {}) => {
    return Object.values(nests).filter((nest) => nest.id);
  }
);

export const selectedNestsNftSelector = createSelector(
  [activeStakeByNameSelector, activeNestNameSelector], (activeStakes, selectedNest) => {
    return activeStakes[selectedNest]?.nfts;
  }
)
