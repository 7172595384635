import React from "react";
import icon from "../../assets/images/list.png";
import styles from "./HistoryPageTitle.module.scss";

const HistoryPageTitle = () => {
  return (
    <div className={styles.title}>
      <div className={styles.textBlock}>
        <img src={icon} alt="icon" />
        <h3>History</h3>
      </div>
    </div>
  );
};

export default HistoryPageTitle;
